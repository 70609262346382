import React from 'react'
import { useStateValue, util } from 'quimera'
import { BaseField } from 'quimera-comps'

export default function TextField(props) {
  // const [state] = useStateValue()

  // const stateField = field || id
  // const stateValue = value || util.getStateValue(stateField, state, value) || ''

  return (
    <BaseField {...props} />
  )
}
