import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, TextField, Typography, Button, Popover } from 'thirdparty'
import { IconButton, Icon /*, Field */ } from 'quimera-comps'
import { useStyles } from './style'

function PopoverTexto ({ opcion, handlePopoverClose, anchorEl, idPadre, alAplicar, ...props }) {
  const classes = useStyles()
  const [fieldText, setFieldText] = useState(opcion.value)

  const handleAplicar = () => {
    // const valor = document.getElementById(`${idPadre}${opcion.nombreCampo}Field1`).value
    alAplicar(fieldText)
  }

  const handleTextFieldKeyUp = (e) => {
    if (e.keyCode === 13 && e.target.value !== '') { // si pulsa intro y el field no esta vacio
      handleAplicar()
    }
  }

  return (
    <Popover
      id={`${idPadre}${opcion.nombreCampo}Popover`}
      open={Boolean(opcion)}
      anchorEl={anchorEl}
      onClose={handlePopoverClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
      <Box>
        <Box className={classes.cabecera}>
          <Box height="100%" display='flex' alignItems='center' justifyContent="space-between">
            <Box flexGrow={1}><Typography style={{ color: 'white' }}>{` ${opcion.labelNombre} `}</Typography></Box>
            <Box><IconButton id='closePopoverTexto' size='small' onClick={() => { handlePopoverClose() }}>
              <Icon style={{ color: 'white', fontSize: '1.1rem' }}>close</Icon>
            </IconButton></Box>
          </Box>
        </Box>
        <Box display='flex' justifyContent="center" alignItems='center'>
          <Typography style={{ fontSize: '15px' }}>Contiene</Typography>
        </Box>
        <Box mx={2} display='flex' justifyContent="center" alignItems='center'>
          <TextField autoFocus /* defaultValue={opcion.value} */
            onChange={event => setFieldText(event.target.value)}
            onKeyUp={handleTextFieldKeyUp}
            value={fieldText} style={{ width: '161px' }} fullWidth id={`${idPadre}${opcion.nombreCampo}Field`} variant="standard" />
        </Box>
        <Box mt={1} display='flex' justifyContent="center" alignItems='center'>
          <Button color='primary' variant='contained' className={classes.botonPrimarioSmall} disabled={fieldText === ''} onClick={ () => handleAplicar()}>APLICAR</Button>
        </Box>
      </Box>
    </Popover>
  )
}

PopoverTexto.propTypes = {
  opcion: PropTypes.object,
  handlePopoverClose: PropTypes.any,
  anchorEl: PropTypes.any,
  idPadre: PropTypes.string,
  alAplicar: PropTypes.any
}

PopoverTexto.defaultProps = {
}

export default PopoverTexto
