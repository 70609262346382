import React from 'react'
import PropTypes from 'prop-types'
import Reference from 'core/components/Block/Reference'
import { TemplateProvider } from 'core/contexts'
import { useTemplateValue } from 'core/hooks'

/**
*   Lets you create Block/Reference components for extension/mix system
*/
function Template ({ id, children }) {
  const templateValue = useTemplateValue()
  const [Parent, ...rest] = templateValue.parents
  const references = React.Children.toArray(children).filter((child) => child.type === Reference)

  return (
    <TemplateProvider value={{
      references: references.concat(templateValue.references),
      parents: rest
    }}>
      <React.Fragment key={id}>
        {
          Parent !== undefined ? (
            <Parent>
              { children }
            </Parent>
          ) : (
            children
          )
        }
      </React.Fragment>
    </TemplateProvider>
  )
}

Template.propTypes = {
  /** Internal id of the template */
  id: PropTypes.string.isRequired,
  /** Children references to pass through */
  children: PropTypes.any
}

Template.defaultProps = {
  parents: [],
  children: []
}

export default Template
