import $ from 'jquery'
import { env } from 'environment'
import { util } from 'quimera'

const apiUrlBase = env.getAPIUrl()

const APIFileCall = async (method, apiUrl, data, filename, success, error) => {
  const token = util.getUserToken()
  var [requestParams] = data
  var url = requestParams ? `${apiUrlBase}${apiUrl}?${$.param(requestParams)}` : `${apiUrlBase}${apiUrl}`
  fetch(url,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token
      }
    }
  )
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = filename
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
      success(null)
    })
}

const APIFileUpload = async (method, apiUrl, data, files, success, error) => {
  const token = util.getUserToken()
  var url = `${apiUrlBase}${apiUrl}`

  const fetchData = {
    method: method,
    headers: {
      Authorization: token
    }
  }
  const fd = new FormData()
  fd.append('files', files)
  // if (requestData) {
  //   fd.append('params', JSON.stringify(requestData))
  // }
  fetchData.body = fd
  const response = await fetch(url, fetchData)
  if (response.ok) {
    const data = await response.json()
    success(data)
  } else {
    const errorText = await response.text()
    error(errorText)
  }
}

const APIcall = async (method, apiUrl, data, success, error) => {
  const token = util.getUserToken()
  var [requestParams, requestData] = data
  var url = requestParams ? `${apiUrlBase}${apiUrl}?${$.param(requestParams)}` : `${apiUrlBase}${apiUrl}`

  const hasFiles = 'FILES' in requestData
  const fetchData = {
    method: method,
    headers: {
      'Content-Type': hasFiles ? 'multipart/form-data' : 'application/json',
      Authorization: token
    }
  }
  if (method !== 'GET') {
    if (hasFiles) {
      const { FILES, ...rD } = requestData
      const formData = new FormData()
      for (const key in rD) {
        formData.append(key, rD[key])
      }
      for (let i = 0; i < FILES.length; i++) {
        formData.append('file' + i.toString(), FILES[i])
      }
      fetchData.body = formData
      delete fetchData.headers['Content-Type']
    } else {
      fetchData.body = JSON.stringify(requestData)
    }
  }
  const response = await fetch(url, fetchData)
  if (response.ok) {
    const data = await response.json()
    success(data)
  } else {
    const errorText = await response.text()
    error(errorText)
  }
}

class QueryRunner {
  defSuccess = (next, dispatch) => res => {
    dispatch({
      type: next,
      payload: res
    })
  }

  defError = (next, dispatch) => res => {
    dispatch({
      type: 'onError',
      payload: {
        type: next,
        error: res
      }
    })
  }

  run (url, params, next, dispatch) {
    const success = this.success ? this.success : this.defSuccess(next, dispatch)
    const error = this.error ? this.error : this.defError(next, dispatch)
    // const success = this.defSuccess(next, dispatch)
    // const error = this.defError(next, dispatch)
    if (this.method === 'GET') {
      APIcall(this.method, url, params.extractGet(), success, error)
    } else {
      APIcall(this.method, url, params.extractPost(), success, error)
    }
  }

  download (url, params, next, filename, dispatch) {
    const success = this.success ? this.success : this.defSuccess(next, dispatch)
    const error = this.error ? this.error : this.defError(next, dispatch)
    // const success = this.defSuccess(next, dispatch)
    // const error = this.defError(next, dispatch)
    if (this.method === 'GET') {
      APIFileCall(this.method, url, params.extractGet(), filename, success, error)
    } else {
      APIFileCall(this.method, url, params.extractPost(), filename, success, error)
    }
  }

  upload (url, params, next, files, dispatch) {
    const success = this.success ? this.success : this.defSuccess(next, dispatch)
    const error = this.error ? this.error : this.defError(next, dispatch)
    // const success = this.defSuccess(next, dispatch)
    // const error = this.defError(next, dispatch)
    if (this.method === 'GET') {
      APIFileUpload('POST', url, params.extractPost(), files, success, error)
    } else {
      APIFileUpload(this.method, url, params.extractPost(), files, success, error)
    }
  }
}

class QueryParams {
  select = null
  filter = null
  order = null
  page = null
  extra = {}

  extract () {
    let params = {}
    params = this.select ? { ...params, fields: this.select } : params
    params = this.filter ? { ...params, filter: JSON.stringify(this.filter) } : params
    params = this.order ? { ...params, order: this.order } : params
    params = this.page ? { ...params, page: JSON.stringify(this.page) } : params

    return [params, this.extra]
  }

  extractGet () {
    let params = {}
    params = this.select ? { ...params, fields: this.select } : params
    params = this.filter ? { ...params, filter: JSON.stringify(this.filter) } : params
    params = this.order ? { ...params, order: this.order } : params
    params = this.page ? { ...params, page: JSON.stringify(this.page) } : params
    params = { ...params, ...this.extra }

    return [params, {}]
  }

  extractPost () {
    // let params = {}
    // params = this.select ? { ...params, fields: this.select } : params
    // params = this.filter ? { ...params, filter: JSON.stringify(this.filter) } : params
    // params = this.order ? { ...params, order: JSON.stringify(this.order) } : params
    // params = this.page ? { ...params, page: JSON.stringify(this.page) } : params

    return [{}, this.extra]
  }
}

class RestQuery {
  constructor (model) {
    this._model = model
    this._url = `${this._model}`
    this._params = new QueryParams()
    this._runner = new QueryRunner()
  }

  get (value, action) {
    this._runner.method = 'GET'
    this._url = value ? `${this._model}/${value}` : `${this._model}`
    this._url = action ? `${this._url}/${action}` : `${this._url}`
    return this
  }

  post (params, action) {
    this._runner.method = 'POST'
    this._url = action ? `${this._url}/-static-/${action}` : `${this._url}`
    if (params) {
      this._params.extra = params
    }
    return this
  }

  put (params) {
    this._runner.method = 'PUT'
    this._params.extra = params
    return this
  }

  patch (pk, action) {
    this._runner.method = 'PATCH'
    this._url = `${this._model}/${pk}`
    this._url = action ? `${this._url}/${action}` : `${this._url}`
    return this
  }

  delete (pk) {
    this._runner.method = 'DELETE'
    this._url = `${this._model}/${pk}`
    return this
  }

  setKeys (keys) {
    this._params.extra = keys
    return this
  }

  set (key, value) {
    this._params.extra[key] = value
    return this
  }

  select (select) {
    this._params.select = select
    return this
  }

  filter (filter) {
    this._params.filter = filter
    return this
  }

  setFiles (files) {
    this._params.extra.FILES = files
    return this
  }

  page (page) {
    this._params.page = page
    return this
  }

  success (success) {
    this._runner.success = success
    return this
  }

  error (error) {
    this._runner.error = error
    return this
  }

  order (order) {
    this._params.order = order
    return this
  }

  go (next, dispatch) {
    this._runner.run(this._url, this._params, next, dispatch)
  }

  download (next, filename, dispatch) {
    this._runner.download(this._url, this._params, next, filename, dispatch)
  }

  upload (next, files, dispatch) {
    this._runner.upload(this._url, this._params, next, files, dispatch)
  }
}

export default (model) => new RestQuery(model)
