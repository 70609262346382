const production = true

const fixed = {
  project: 'dailyjob',
  apiUrl: 'https://api.dailyjob.io/api/',
  title: 'dailyjob'
}

const defEnv = {
  project: localStorage.getItem('currentProject') || 'default',
  apiUrl: localStorage.getItem('apiUrl') || 'http://127.0.0.1:8005/api/',
  title: ''
}

export const env = {
  getCurrentProject: () => production ? fixed.project : defEnv.project,
  getAPIUrl: () => production ? fixed.apiUrl : defEnv.apiUrl,
  getCurrentTitle: () => production ? fixed.title : defEnv.title,
  inDevelopment: () => !production,
  GTMKey: () => production ? 'GTM-P67HFQT' : '',
  avatarPath: '/static/images/imagenes_avatar/'
}
