import { util } from 'quimera'

export default class BaseController {
  state = {}
  initialState = {}

  constructor (s) {
    this.state = s
    this.initialState = { ...s }
  }

  onInit (s, p) {
    return this.state
  }

  setState (s) {
    this.state = s
    return s
  }

  deepUpdate (state, keys, value) {
    if (!keys.length) {
      return value
    }
    const [key, ...rest] = keys
    return {
      ...state,
      [key]: this.deepUpdate(state[key], rest, value)
    }
  }

  onError (state, payload) {
    util.appDispatch({ type: 'onErrorProducido', payload: { error: payload.error, type: payload.type } })
    return state
  }

  setListItem (state, { path, key, item }) {
    console.log('setListItem', path, key, item)
    console.log('setListItem state', state)

    return this.setState({
      ...state,
      [path]: state[path].map(i => i[key.name] === key.value ? item : i)
    })
  }

  prependListItem (state, { path, item }) {
    console.log('setListItem', path, item)
    console.log('setListItem state', state)

    return this.setState({
      ...state,
      [path]: [item, ...state[path]]
    })
  }

  // onObjectKeySaved (state, payload) {
  //   console.log('onObjectKeySaved', payload)
  //   const { changes, keyName } = payload
  //   const copyKey = keyName + 'Copy'
  //   // const copyKey = 'copy'
  //   if (!(copyKey in state)) {
  //     return state
  //   }
  //   const newState = { ...state }
  //   newState[copyKey] = {
  //     ...newState[copyKey],
  //     ...changes
  //   }
  //   return this.setState(newState)
  // }

  saveStateKey (state, { path, key, value }) {
    console.log('saveStateKey', path, key, value)
    if (!state[path]) {
      return state
    }
    const newState = { ...state }
    newState[path][key] = value

    return this.setState(newState)
  }

  saveStateKeys (state, payload) {
    console.log('saveStateKeys', payload)
    const { changes, path } = payload
    if (!(path in state)) {
      return state
    }
    const newState = { ...state }
    newState[path] = {
      ...newState[path],
      ...changes
    }
    return this.setState(newState)
  }

  setStateKey (state, { path, value }) {
    console.log('setStateKey', path, value, state)
    // Funcion recursiva
    const newState = this.deepUpdate(state, path.split('.'), value)
    console.log('newState', newState)
    return this.setState(newState)
  }

  setStateKeys (state, { path, keys }) {
    console.log('setStateKeys', path, keys, state)
    // Asumimos solo claves de primer nivel (sin path). Para implementar el path usar función recursiva
    const newState =
      path ? ({
        ...state,
        [path]: {
          ...state[path],
          ...keys
        }
      })
        : ({
          ...state,
          ...keys
        })
    console.log('newState', newState)
    return this.setState(newState)
  }

  setStateArrayKey (state, { path, value }) {
    console.log('setStateArrayKey', path, value)
    // Funcion recursiva
    return this.setState(
      this.deepUpdate(state, path.split('.'), [...value])
    )
  }

  filterListItems (state, { path, filter }) {
    console.log('setStateKeys', path, filter)
    return this.setState({
      ...state,
      [path]: state[path].filter(filter)
    })
  }
}
