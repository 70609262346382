import React from 'react'
import PropTypes from 'prop-types'
// import { BaseField } from 'quimera-comps'
import SelectField from 'quimera-comps/Field/SelectField/SelectField'

function SelectBase ({ ...props }) {

  return (
    <SelectField {...props}/>
  )
}

SelectBase.propTypes = {
  /** Props to pass through */
  selectprops: PropTypes.object
}

SelectBase.defaultProps = {
  selectprops: {}
}

export default SelectBase
