import React, { useState } from 'react'
import { Field, Icon, IconButton } from 'quimera-comps'

export default function PasswordField ({ ...props }) {
  const [showPass, setShowPass] = useState(false)

  return (
    <Field.Text
      type={showPass ? 'text' : 'password'}
      endAdornment={
        <IconButton
          id = {`${props.id}ShowPassword`}
          aria-label='toggle password visibility'
          onClick={() => setShowPass(!showPass)}
          onMouseDown={event => event.preventDefault()}
        >
          {showPass ? <Icon>visibility_off</Icon> : <Icon>visibility</Icon>}
        </IconButton>
      }
      {...props}/>
  )
}
