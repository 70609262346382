import React from 'react'
import PropTypes from 'prop-types'
import { BaseField } from 'quimera-comps'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from 'thirdparty'
import TimeField from 'quimera-comps/Field/TimeField/TimeField'

function TimeBase ({ timePickerProps, ...props }) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <BaseField
        Component={
          <TimeField {...timePickerProps}/>
        }
        {...props}
      />
    </MuiPickersUtilsProvider>
  )
}

TimeBase.propTypes = {
  /** Props to pass through */
  timePickerProps: PropTypes.object
}

TimeBase.defaultProps = {
  timePickerProps: {}
}

export default TimeBase
