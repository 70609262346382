import 'react-app-polyfill/stable'
import 'react-app-polyfill/ie9'
import { env } from 'environment'
import TagManager from 'react-gtm-module'

import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './core/components/App'
import * as serviceWorker from './serviceWorker'

// const { init, UseGTMHookProvider } = useGTM()
const GTMId = env.GTMKey()
// GTM-P67HFQT para app
// GTM-MPJ2L2T para demo

const tagManagerArgs = {
  gtmId: GTMId
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
