import React from 'react'
import { util } from 'quimera'
import TableColumn from 'quimera-comps/Table/TableColumn'

function formatCellValue (value) {
  return `${Number(value).toLocaleString(util.getLocale(), { minimumFractionDigits: 2, maximumFractionDigits: 2 })}€`
}

/**
* CurrencyColumn
*/
function CurrencyColumn (props) {
  return (
    <TableColumn columnType="CurrencyColumn" align='right' format={ formatCellValue } {...props}/>
  )
}

export default CurrencyColumn
