import React from 'react'
import TableColumn from 'quimera-comps/Table/TableColumn'

function formatCellValue (value) {
  return value.toString()
}

/**
* TextColumn
*/
function BooleanColumn (props) {
  return (
    <TableColumn columnType="BooleanColumn" align='left' format={ formatCellValue } {...props} />
  )
}

export default BooleanColumn
