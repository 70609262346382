import React from 'react'
import PropTypes from 'prop-types'
import TableColumn from 'quimera-comps/Table/TableColumn'

/**
* DecimalColumn
*/
function DecimalColumn ({ decimals, ...props }) {
  function formatCellValue (value) {
    return Number(value).toLocaleString('es', { minimumFractionDigits: decimals, maximumFractionDigits: decimals })
  }

  return (
    <TableColumn columnType="DecimalColumn" align='right' format={ formatCellValue } {...props}/>
  )
}

DecimalColumn.propTypes = {
  /** Number of decimals */
  decimals: PropTypes.number
}

DecimalColumn.defaultProps = {
  decimals: 2
}

export default DecimalColumn
