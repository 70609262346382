import React, { createContext, useReducer, useCallback } from 'react'
import PropTypes from 'prop-types'

export const StateContext = createContext()

const StateProvider = ({ reducer, apiClient, initialState, children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const apiMiddleware = next => action => {
    apiClient(action, apiDispatch)
    return next(action)
  }

  const apiDispatch = useCallback(apiMiddleware(dispatch), [])

  return (
    <StateContext.Provider value={[state, apiDispatch]}>
      {children}
    </StateContext.Provider>
  )
}

StateProvider.propTypes = {
  /** Ctrl */
  reducer: PropTypes.any,
  /** API Ctrl */
  apiClient: PropTypes.any,
  /** Initial State */
  initialState: PropTypes.any,
  /** Children elements to pass through */
  children: PropTypes.any
}

export { StateProvider }
