import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { /* DatePicker, */ KeyboardDatePicker } from 'thirdparty'
import { util } from 'quimera'

function DateField ({ value, onChange, clearLabel, ...props }) {
  const [showedValue, setShowedValue] = useState(parseValue(value))

  function parseValue (value) {
    return value ? Date.parse(value) : value
  }

  function isValidDate (d) {
    return (d instanceof Date && !isNaN(d)) || d === null // null si pulsamos a borrar
  }

  function handleDateChange (value) {
    setShowedValue(value)
    if (!isValidDate(value)) {
      return
    }
    // convertir a timestamp, agregar timezone offset, después convertir de nuevo a date object y por útimo usar toISOString()
    const localDateTime = value ? new Date(value.getTime() - (value.getTimezoneOffset() * 60000)).toISOString().substr(0, 10) : null
    return onChange({
      target: {
        value: value ? /* value.toISOString().substr(0, 10) */ localDateTime : value
      }
    })
  }

  useEffect(() => {
    setShowedValue(parseValue(value))
  }, [value])

  return (
    <KeyboardDatePicker
      value={ showedValue }
      // autoOk={false}
      // inputVariant='outlined'
      format= {util.getFormatWordsDate()} /* 'dd/MM/yyyy' */
      onChange={handleDateChange}
      cancelLabel='CANCELAR'
      clearLabel= {clearLabel ||'BORRAR'}
      okLabel='ACEPTAR'
      margin='dense'
      clearable
      KeyboardButtonProps={{
        size: 'small'
      }}
      invalidDateMessage='Formato de fecha no válida'
      maxDateMessage='La fecha no debe ser superior a la máxima'
      {...props}
    />
  )
}

DateField.propTypes = {
  /** Field's value */
  value: PropTypes.any,
  /** Function to execute onChange */
  onChange: PropTypes.func
}

DateField.defaultProps = {}

export default DateField
