import React, { useState } from 'react'
import PropTypes from 'prop-types'
// import { util } from 'quimera'
import { Box, TextField, Typography, Button, Popover, FormControl, Select, MenuItem } from 'thirdparty'
import { IconButton, Icon, NumberFormat /*, Field */ } from 'quimera-comps'
import { useStyles } from './style'
// const thousandSeparator = '.'
// const thousandSeparatorRe = '\\.'
const decimalSeparator = ','//
// const decimalSeparatorRe = ','

const signos = ['>=', '=', '<=']

function PopoverNumber({ opcion, handlePopoverClose, anchorEl, idPadre, alAplicar, sinSignos, ...props }) {
  const classes = useStyles()
  const [fieldValue, setFieldValue] = useState(opcion.value)
  const [selectValue, setSelectValue] = useState(opcion.signo || signos[1])
  const decimales = opcion.decimal ? 2 : 0

  const handleAplicar = () => {
    // const valor = document.getElementById(`${idPadre}${opcion.nombreCampo}Field1`).value
    sinSignos ? alAplicar({ value: fieldValue }) : alAplicar({ value: fieldValue, signo: selectValue })
  }

  const handleFieldKeyUp = (e) => {
    if (e.keyCode === 13 && e.target.value !== '') { // si pulsa intro y el field no esta vacio
      handleAplicar()
    }
  }

  const handleFieldChange = (e, o) => {
    const newValue = e.target.value
    // newValue = util.formatter(newValue, decimales)
    setFieldValue(newValue)
  }

  const handleSelectChange = e => {
    const value = e.target.value
    setSelectValue(value)
  }

  return (
    <Popover
      id={`${idPadre}${opcion.nombreCampo}Popover`}
      open={Boolean(opcion)}
      anchorEl={anchorEl}
      onClose={handlePopoverClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
      <Box>
        <Box className={classes.cabecera}>
          <Box height="100%" display='flex' alignItems='center' justifyContent="space-between">
            <Box flexGrow={1}><Typography style={{ color: 'white' }}>{` ${opcion.labelNombre} `}</Typography></Box>
            <Box><IconButton size='small' onClick={() => { handlePopoverClose() }}>
              <Icon style={{ color: 'white', fontSize: '1.1rem' }}>close</Icon>
            </IconButton></Box>
          </Box>
        </Box>
        {/* <Box display='flex' justifyContent="center" alignItems='center'>
          <Typography style={{ fontSize: '15px' }}>Contiene</Typography>
        </Box> */}
        <Box mx={2} display='flex' justifyContent="center" alignItems='center'>

          {!sinSignos && <FormControl style={{ minWidth: 60, marginRight: '16px' }} >
            <Select labelId={`${idPadre}${opcion.nombreCampo}SelectLabel`} id={`${idPadre}${opcion.nombreCampo}Select`}
              value={selectValue} onChange={handleSelectChange}
              renderValue={op =>
                <div key={`MenuItemSelection${idPadre}${opcion.nombreCampo}`} value={op} style={{ textAlign: 'center' }}>{op}</div>
              }>
              {signos.map((signo, index) =>
                <MenuItem key={`selectSigno${index}`} value={signo} style={{ minHeight: '40px' }}><span style={{ textAlign: 'center', width: '100%' }}>{signo}</span></MenuItem>
              )}

            </Select>
          </FormControl>}

          {/* <TextField autoFocus
            onChange={handleTextFieldChange}
            onKeyUp={handleTextFieldKeyUp}
            value={fieldText} style={{ width: '161px' }} fullWidth id={`${idPadre}${opcion.nombreCampo}Field`} variant="standard" /> */}
          <NumberFormat
            // thousandSeparator={thousandSeparator}
            decimalSeparator={decimalSeparator}
            fixedDecimalScale={true}
            decimalScale={decimales}
            // variant='outlined'
            margin='dense'
            inputProps={{ style: { textAlign: 'end' } }}
            customInput={TextField}
            // new
            style={{ width: 120 }}
            variant="standard"
            id={`${idPadre}${opcion.nombreCampo}Field`}
            autoFocus
            fullWidth
            value={fieldValue}
            onChange={handleFieldChange}
            onKeyUp={handleFieldKeyUp}
          />

        </Box>
        <Box mt={1} display='flex' justifyContent="center" alignItems='center'>
          <Button color='primary' variant='contained' className={classes.botonPrimarioSmall} disabled={fieldValue === ''} onClick={() => handleAplicar()}>APLICAR</Button>
        </Box>
      </Box>
    </Popover>
  )
}

PopoverNumber.propTypes = {
  opcion: PropTypes.object,
  handlePopoverClose: PropTypes.any,
  anchorEl: PropTypes.any,
  idPadre: PropTypes.string,
  alAplicar: PropTypes.any,
  sinSignos: PropTypes.bool
}

PopoverNumber.defaultProps = {
}

export default PopoverNumber
