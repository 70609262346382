import { util } from 'quimera'
import { getRules, getRulesByComplement } from 'core/hooks/useManager'

export default {
  can: (action) => {
    const rules = getRules()
    const idPlan = util.getUser()?.idplan 
    return rules?.[idPlan]?.[action] ?? (rules?.[idPlan]?.access ?? (rules?.access || false))
    // return (rules?.access || ((rules?.[idPlan]?.access && (rules?.[idPlan]?.[action] ?? true)) || rules?.[idPlan]?.[action])) ?? false
  },
  canByComplement: (action) => {
    const rules = getRulesByComplement()
    const rule = rules?.[action]
    if (!rule) {
      return false
    }
    const complements = util.getUser()?.complementosCompania 
    const intereseccion = rule.filter(value => complements ? complements.includes(value) : [])
    return intereseccion.length > 0
    // return complements.includes(rules?.[action][0])
  },
  complementsByRule: (action) => {
    const rules = getRulesByComplement()
    return rules?.[action]
  }
}
