import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from 'thirdparty'
import ItemGrid from 'quimera-comps/Grid/ItemGrid'
import HGrid from 'quimera-comps/Grid/HGrid'
import VGrid from 'quimera-comps/Grid/VGrid'

const ContainerGrid = React.forwardRef(({ childrenProps, children, ...props }, ref) => {
  const newChildren = React.Children.toArray(children).map((child, idx) => {
    if (child.type !== ItemGrid && child.type !== HGrid && child.type !== VGrid) {
      return <ItemGrid key={child.key || child.name || idx} {...childrenProps}>{child}</ItemGrid>
    }
    if (!child.props.item) {
      child = React.cloneElement(child, { item: true, ...child.props })
    }
    if (!child.props.xs) {
      child = React.cloneElement(child, { xs: true, ...child.props })
    }
    return React.cloneElement(child, { ...childrenProps, key: child.key || child.name || idx, ...child.props })
  })

  return (
    <Grid container spacing={1} {...props} ref={ref}>
      { newChildren }
    </Grid>
  )
})

ContainerGrid.propTypes = {
  /** Children props to pass through */
  childrenProps: PropTypes.any,
  /** Children elements to pass through */
  children: PropTypes.any
}

ContainerGrid.defaultProps = {}

ContainerGrid.displayName = 'ContainerGrid'
export default ContainerGrid
