import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Typography, Button, Popover /* , InputLabel */, MenuItem, Select, FormControl, Tooltip } from 'thirdparty'
import { IconButton, Icon, Field } from 'quimera-comps'
import { useStyles } from './style'

const IconPersistente = ({ ...props }) => <Tooltip title='Fecha persistente'><Icon fontSize="small" style={{ marginRight: '5px' }}>all_inclusive</Icon></Tooltip>

const obtenerOpcionFecha = (opcion) => esModoEdicion(opcion.value) ? opcion.value : opcion.opcionesPredefinidas[0]
const esModoEdicion = (value) => value.fecha || value.persistencia || value.desde || value.hasta
// const getInicialFechas = (value) => value.persistencia ? { fecha: null, desde: null, hasta: null } : value

function PopoverBoolean ({ opcion, handlePopoverClose, anchorEl, idPadre, alAplicar, ...props }) {
  const classes = useStyles()
  const [fechas, setFechas] = useState(opcion.value /* getInicialFechas(opcion.value) */)
  const [opcionFecha, setOpcionFecha] = useState(/* opcion.opcionesPredefinidas[0] */ obtenerOpcionFecha(opcion))

  // console.log('estoy en popoverDate, este es mi opcion', JSON.parse(JSON.stringify(opcion)))
  const handleAplicar = (value) => {
    alAplicar(value || fechas)
    // console.log('aplico date fecha: ', fechas, 'opcionfecha: ', opcionFecha)
  }

  const handleSelectChange = (e) => {
    const value = e.target.value
    // console.log('handleSelectChange value:', value)
    if (value.persistencia) { // si es un campo con persistencia aplicamos sin dejar editar
      // console.log('con persistencia, handleSelectChange, value: ', value)
      /* setFechas(value); */ handleAplicar(value); return
    }
    setOpcionFecha(value)
    setFechas(value)
    // console.log(value)
  }

  const handleDateFieldChange = (e, campo) => {
    const newValue = e.target.value
    // fechas[campo] = newValue
    setFechas({
      ...fechas,
      [campo]: newValue
    })
    // console.log(newValue, campo)
  }

  return (
    <Popover
      id={`${idPadre}${opcion.nombreCampo}Popover`}
      open={Boolean(opcion)}
      anchorEl={anchorEl}
      onClose={handlePopoverClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
      <Box>
        <Box className={classes.cabecera}>
          <Box height="100%" display='flex' alignItems='center' justifyContent="space-between">
            <Box flexGrow={1}><Typography style={{ color: 'white' }}>{` ${opcion.labelNombre} `}</Typography></Box>
            <Box><IconButton size='small' onClick={() => { handlePopoverClose() }}>
              <Icon style={{ color: 'white', fontSize: '1.1rem' }}>close</Icon>
            </IconButton></Box>
          </Box>
        </Box>
        <Box mx={2} mt={2} display='flex' justifyContent="center" alignItems='center'>
          <FormControl style={{ width: 200 }} >
            <Select displayEmpty labelId={`${idPadre}${opcion.nombreCampo}SelectLabel`} id={`${idPadre}${opcion.nombreCampo}Select`}
              value={opcionFecha} onChange={handleSelectChange}
              renderValue={op =>
                op.nombre === ''
                  ? <div key={`MenuItemSelection${idPadre}${opcion.nombreCampo}`} value={op} style={{ color: 'lightGrey' }}>{opcion.labelNombre}</div>
                  : <div key={`MenuItemSelection${idPadre}${opcion.nombreCampo}`} style={{ display: 'flex', alignItems: 'center' }} value={op}>{op.persistencia && <IconPersistente/>}{op.nombre}</div>
              }>
              {opcion.opcionesPredefinidas.map((op, index) =>
                op.nombre === ''
                  ? <MenuItem key={`MenuItem${index}${idPadre}${opcion.nombreCampo}`} value={op} style={{ color: 'lightGrey', minHeight: '40px' }}>{opcion.labelNombre}</MenuItem>
                  : <MenuItem key={`MenuItem${index}${idPadre}${opcion.nombreCampo}`} value={op} style={{ minHeight: '40px', display: 'flex', alignItems: 'center' }}>{op.persistencia && <IconPersistente/>}{op.nombre}</MenuItem>
              )}
            </Select>
          </FormControl>
        </Box>
        {!fechas.persistencia &&
        <>
          {fechas.fecha || (opcionFecha.nombre !== '' && !fechas.desde && !fechas.hasta)
            ? <Box mx={2} mt={2} display='flex' justifyContent="center" alignItems='center'>
              <Field.Date style={{ width: 200 }} value={fechas.fecha}
                onChange={ (ev) => handleDateFieldChange(ev, 'fecha')} label={'Fecha: '}
                disabled={Boolean(fechas.desde !== null || fechas.hasta !== null)} autoOk/>
            </Box>
            : null}

          {fechas.desde || (opcionFecha.nombre === '' && !fechas.fecha)
            ? <Box mx={2} mt={2} display='flex' justifyContent="center" alignItems='center'>
              <Field.Date style={{ width: 200 }} value={fechas.desde || null}
                onChange={ (ev) => handleDateFieldChange(ev, 'desde')} label={'Desde: '}
                disabled={fechas.fecha !== null && !fechas.persistencia} autoOk/>
            </Box>
            : null}

          {fechas.hasta || (opcionFecha.nombre === '' && !fechas.fecha)
            ? <Box mx={2} mt={2} display='flex' justifyContent="center" alignItems='center'>
              <Field.Date style={{ width: 200 }} value={fechas.hasta || null}
                onChange={ (ev) => handleDateFieldChange(ev, 'hasta')} label={'Hasta: '}
                disabled={fechas.fecha !== null && !fechas.persistencia} autoOk/>
            </Box>
            : null}
        </>
        }
        {/* console.log('EN RENDRE DE FECHASM fechas', JSON.parse(JSON.stringify(fechas))) */}
        <Box mt={1} display='flex' justifyContent="center" alignItems='center'>
          <Button color='primary' variant='contained' className={classes.botonPrimarioSmall} disabled={fechas.hasta === null && fechas.desde === null && fechas.fecha === null}
            onClick={ () => handleAplicar()}>APLICAR</Button>
        </Box>
      </Box>
    </Popover>
  )
}

PopoverBoolean.propTypes = {
  opcion: PropTypes.object,
  handlePopoverClose: PropTypes.any,
  anchorEl: PropTypes.any,
  idPadre: PropTypes.string,
  alAplicar: PropTypes.any
}

PopoverBoolean.defaultProps = {
}

export default PopoverBoolean
