import React, { useState, useEffect } from 'react'
import { useStateValue, util } from 'quimera'
import PropTypes from 'prop-types'
import { Box, Chip, Autocomplete, TextField } from 'thirdparty'
import { IconButton, Icon, Typography } from 'quimera-comps'
import { PopoverAPIField, PopoverBoolean, PopoverCheckBoxMultiple, PopoverTexto, PopoverDate, PopoverNumber } from './Popovers'
import { ChipAPIField, ChipDate } from './Chips'
import { cargarFiltro, filtrosPredAFiltrosAdaptados, volcarFiltro } from './funciones'
import { ChipLabel } from './Chips/ChipLabel'

function FiltroDinamico({ id, propiedades, valores, filtrosPredefinidos, textFieldProps, disabled, InputProps, maxChipWidth, buscadorContiene, ...props }) {
  const [, dispatch] = useStateValue()
  const [anchorEl, setAnchorEl] = useState(null)
  const [opcionSelecionada, setOptionSelecionada] = useState(null)
  const [autocompeteValue, setAutocompeteValue] = useState([])
  const [opcionesAutocomplete, setOpcionesAutocomplete] = useState(propiedades.filter(e => !e.deleteDisabled))
  const [textFieldAutocompleteValue, setTextFieldAutocompleteValue] = useState('')

  const classes = {
    iconoFiltro: {
      size: '1.2rem',
      marginRight: '4px'
    }
  }

  const handleChipClick = (event, opcion) => {
    if (!disabled) {
      setAnchorEl(event.currentTarget)
      setOptionSelecionada(opcion)
    }

  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
    setOptionSelecionada(null)
  }

  const hacerYAplicarFiltro = (opciones, accion) => {
    dispatch({
      type: `on${util.camelId(id)}Changed`,
      payload: { field: util.lastStateField(id), value: volcarFiltro(opciones, propiedades) }
    })
  }

  const handleAutocompleteChange = (event, value, reason) => {
    // Filtering options that cannot be removed.
    const noDeleteValue = value.filter(e => !e.deleteDisabled)
    const noDeleteAutoCompleteValue = autocompeteValue.filter(e => !e.deleteDisabled)

    event.preventDefault()
    let newVAl = value.filter(e => !autocompeteValue.find(a => e.key === a.key))[0]
    if (newVAl && newVAl.tipo !== 'normal') {
      setAutocompeteValue(newVAl.propiedades)
      hacerYAplicarFiltro(newVAl.propiedades, 'aplicarFILTRODEFILTROS')
      return
    }

    if (noDeleteValue.length < noDeleteAutoCompleteValue.length) {
      setAutocompeteValue(value)
      hacerYAplicarFiltro(value, 'borrar con tecla')
      document.getElementById(`${id}Autocomplete`).blur()
      return
    }

    setAnchorEl(document.getElementById(`${id}Autocomplete`))
    setOptionSelecionada(value.filter(e => !autocompeteValue.find(a => e.nombreCampo === a.nombreCampo))[0])
  }

  const handleChipDelete = (event, opcion) => {
    setAnchorEl(null)
    setOptionSelecionada(null)
    if (opcion.opciones) {
      opcion.opciones = opcion.opciones.map(o => ({ key: o.key, value: false }))
    }
    const newAutocompeteValue = autocompeteValue.filter(el => el.nombreCampo !== opcion.nombreCampo)
    setAutocompeteValue(newAutocompeteValue)
    hacerYAplicarFiltro(newAutocompeteValue, 'borrar')
  }

  const alAplicar = (value) => {
    if (value === '') { setAnchorEl(null); setOptionSelecionada(null); return }
    const opcionEncontrada = autocompeteValue.filter(el => el.nombreCampo === opcionSelecionada.nombreCampo)[0]
    const aCambiar = Object.assign({}, opcionSelecionada)
    const nuevosValores = autocompeteValue.slice()

    switch (opcionSelecionada.tipoCampo) {
      case 'string':
        aCambiar.value = value
        break

      case 'checkboxmultiple':
        aCambiar.value = value.chipValue
        aCambiar.opciones = value.opciones
        break

      // case 'nullable':
      //   aCambiar.value = value ? 'is_not_null' : 'is_null'

      case 'boolean':
        aCambiar.value = value
        break

      case 'apiselect':
        aCambiar.value = value
        break

      case 'date':
        aCambiar.value = value
        break

      case 'number':
        aCambiar.value = value.value
        aCambiar.signo = value.signo
        break

      default:
        alert('Error en FiltroDinamico, tipoCampo incorrecto.')
        break
    }

    if (opcionEncontrada !== undefined) {
      nuevosValores[nuevosValores.findIndex(el => el.nombreCampo === opcionSelecionada.nombreCampo)] = aCambiar
    } else {
      nuevosValores.push(aCambiar)
    }
    setAutocompeteValue(nuevosValores)
    setAnchorEl(null); setOptionSelecionada(null)
    hacerYAplicarFiltro(nuevosValores, 'aplicar')
  }

  const handleTextFieldKey = (event) => {
    if (event.keyCode === 13 && !autocompeteValue.find(prop => prop.porDefecto === true) && event.target.value.length > 0) {
      const newOption = { ...propiedades.find(prop => prop.porDefecto === true) }
      newOption.value = textFieldAutocompleteValue
      const nuevosValores = autocompeteValue.slice()
      nuevosValores.push(newOption)
      setAutocompeteValue(nuevosValores)
      setTextFieldAutocompleteValue('')
      hacerYAplicarFiltro(nuevosValores, 'introDefault')
      document.getElementById(`${id}Autocomplete`).blur()
    }
  }

  const filtrarOpcionesSeleccionadas = (opcsSelec, state) => {
    const { inputValue } = state
    let opcsSinSelec = opcsSelec.filter(op => autocompeteValue.findIndex(op2 => op2.key === op.key) === -1)
    opcsSinSelec = inputValue !== '' ? opcsSinSelec.filter(op => op.labelNombre.toUpperCase().includes(inputValue.toUpperCase())) : opcsSinSelec
    return opcsSinSelec
  }

  useEffect(() => {
    const filtrosPredefinidosAdaptados = filtrosPredAFiltrosAdaptados(propiedades, filtrosPredefinidos)
    filtrosPredefinidosAdaptados.forEach(v => { v.tipo = 'filtroDeFiltros' })
    filtrosPredefinidosAdaptados.forEach(v => { propiedades.unshift(v) })
    propiedades.map((prop, index) => { prop.key = index })
    setOpcionesAutocomplete(propiedades.filter(e => !e.deleteDisabled))
  }, [filtrosPredefinidos])

  useEffect(() => {
    if (valores) {
      const filtro = cargarFiltro(valores, propiedades)
      setAutocompeteValue(filtro)
    }
  }, [valores])

  return (
    <>
      <Box display='flex' alignItems='flex-end' >
        {/* <Box flexGrow={0} >
          <IconButton id={id + 'Icono'} size='small' onClick={() => { document.getElementById(`${id}Autocomplete`).click() }}>
            <Icon style={{ color: '#293333' }}>filter_alt</Icon>
          </IconButton>
        </Box> */}
        <Box flexGrow={1} >
          <Autocomplete value={autocompeteValue} multiple id={`${id}Autocomplete`} variant='standard'
            disableClearable
            options={opcionesAutocomplete}
            onChange={handleAutocompleteChange}
            noOptionsText={
              opcionesAutocomplete.find(prop => prop.porDefecto === true)
                ? autocompeteValue.find(prop => prop.porDefecto === true) ? ''
                  : `${opcionesAutocomplete.find(prop => prop.porDefecto === true).labelChip} ${textFieldAutocompleteValue} (Pulsa intro)` : ''}
            getOptionLabel={option => option.labelNombre}
            // renderOption={option =>
            //   option.tipo === 'filtroDeFiltros'
            //     ? <div style={ option.labelNombre.substring(0, 1) !== '*' ? { color: 'black', fontStyle: 'italic' } : { color: 'black', fontWeight: 1000 }}>{option.labelNombre.substring(0, 1) !== '*' ? `*${option.labelNombre}` : `${option.labelNombre}`}</div>
            //     : <Box display='flex' alignItems='center'>
            //       {/* <Icon fontSize='small'>add_circle_outline</Icon> */}
            //       <Icon style={{ fontSize: '1.2rem', marginRight: '4px' }}>add_circle_outline</Icon>

            //       <Typography variant='body1'>{`${option.labelNombre}`}</Typography>
            //     </Box>
            //   }
            renderOption={option =>
              <Box display='flex' alignItems='center'>
                <Icon style={{ fontSize: '1.2rem', marginRight: '4px' }}>add_circle_outline</Icon>
                {/* <Icon className={classes.iconoFiltro}>add_circle_outline</Icon> */}
                <Typography variant='body1'>{`${option.labelNombre}`}</Typography>
              </Box>
            }

            filterOptions={filtrarOpcionesSeleccionadas}

            renderTags={(value, getTagProps) =>
              value.map((option, index) => (

                option.tipoCampo === 'apiselect'
                  ? <ChipAPIField option={option} onClickear={handleChipClick} alEliminar={handleChipDelete} key={index} clave={index} getTagProps={getTagProps} disabled={disabled} maxWidth={maxChipWidth} />

                  : option.tipoCampo === 'date'
                    ? <ChipDate option={option} onClickear={handleChipClick} alEliminar={handleChipDelete} key={index} clave={index} getTagProps={getTagProps} disabled={disabled} maxWidth={maxChipWidth} />

                    : <Chip variant="outlined" key={index} {...getTagProps({ index })}
                      label={<ChipLabel maxWidth={maxChipWidth}>{
                        option.tipoCampo === 'string' || option.tipoCampo === 'checkboxmultiple'
                          ? option.value === '' ? [option.nombreCampo] : [`${option.labelChip} ${option.value}`]

                          : option.tipoCampo === 'boolean'
                            ? `${option.quitarLabelChip ? '' : option.labelChip} ${option.value ? option.textoTrue : option.textoFalse}`

                            : option.tipoCampo === 'number'
                              ? `${option.labelChip}  ${option.sinSignos ? '' : option.signo}  ${option.value}`

                              : ''}</ChipLabel>}
                      onClick={(event) => handleChipClick(event, option)}
                      onDelete={!disabled && ((event) => handleChipDelete(event, option))}
                      disabled={disabled && false} />
              ))
            }

            renderInput={(params) => (
              <TextField value={textFieldAutocompleteValue}
                onChange={event => setTextFieldAutocompleteValue(event.target.value)}
                onKeyDown={buscadorContiene ? handleTextFieldKey : null}
                // onKeyDown={handleTextFieldKey}
                id={`${id}Input`}
                inputProps={{ id: `${id}InputInput` }}
                fullWidth label="Busca" variant="standard"
                {...textFieldProps} {...params} InputProps={{ ...params.InputProps, ...InputProps }} />
            )}
            disabled={disabled}
            {...props} // Importante a tener en cuenta para personalizacion
          />
        </Box>
      </Box>

      {opcionSelecionada && opcionSelecionada.tipoCampo === 'string'
        ? <PopoverTexto alAplicar={alAplicar} opcion={opcionSelecionada} handlePopoverClose={handlePopoverClose} anchorEl={anchorEl} idPadre={id} />

        : opcionSelecionada && opcionSelecionada.tipoCampo === 'checkboxmultiple'
          ? <PopoverCheckBoxMultiple alAplicar={alAplicar} opcion={opcionSelecionada} handlePopoverClose={handlePopoverClose} anchorEl={anchorEl} idPadre={id} />

          : opcionSelecionada && opcionSelecionada.tipoCampo === 'boolean'
            ? <PopoverBoolean alAplicar={alAplicar} opcion={opcionSelecionada} handlePopoverClose={handlePopoverClose} anchorEl={anchorEl} idPadre={id} />

            : opcionSelecionada && opcionSelecionada.tipoCampo === 'apiselect'
              ? <PopoverAPIField alAplicar={alAplicar} opcion={opcionSelecionada} handlePopoverClose={handlePopoverClose} anchorEl={anchorEl} idPadre={id} componente={opcionSelecionada.componente} />

              : opcionSelecionada && opcionSelecionada.tipoCampo === 'date'
                ? <PopoverDate alAplicar={alAplicar} opcion={opcionSelecionada} handlePopoverClose={handlePopoverClose} anchorEl={anchorEl} idPadre={id} />

                : opcionSelecionada && opcionSelecionada.tipoCampo === 'number'
                  ? <PopoverNumber alAplicar={alAplicar} opcion={opcionSelecionada} handlePopoverClose={handlePopoverClose} anchorEl={anchorEl} idPadre={id} sinSignos={opcionSelecionada.sinSignos} />

                  : null}
    </>
  )
}

FiltroDinamico.propTypes = {
  id: PropTypes.string,
  propiedades: PropTypes.array,
  filtrosPredefinidos: PropTypes.array,
  textFieldProps: PropTypes.object,
  disabled: PropTypes.bool,
  buscadorContiene: PropTypes.bool,
  maxChipWidth: PropTypes.number
}

FiltroDinamico.defaultProps = {
  propiedades: [],
  filtrosPredefinidos: []
}

export default FiltroDinamico
