import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { util } from 'quimera'
import { Field } from 'quimera-comps'

function IntervalField ({ label, value, onChange, ...props }) {
  function getIntervalList () {
    return [
      { value: '', label: (<em>Vacío</em>), date1: () => null, date2: () => null },
      { value: 'today', label: 'Hoy', date1: util.today, date2: util.today },
      { value: 'yesterday', label: 'Ayer', date1: util.yesterday, date2: util.yesterday },
      { value: 'tomorrow', label: 'Mañana', date1: util.tomorrow, date2: util.tomorrow },
      { value: 'thisweek', label: 'Esta semana', date1: util.firstOfWeek, date2: util.lastOfWeek },
      { value: 'previousweek', label: 'Semana pasada', date1: util.firstOfPreviousWeek, date2: util.lastOfPreviousWeek },
      { value: 'nextweek', label: 'Próxima semana', date1: util.firstOfNextWeek, date2: util.lastOfNextWeek },
      { value: 'thismonth', label: 'Este mes', date1: util.firstOfMonth, date2: util.lastOfMonth },
      { value: 'previousmonth', label: 'Mes pasado', date1: util.firstOfPreviousMonth, date2: util.lastOfPreviousMonth },
      { value: 'nextmonth', label: 'Próximo mes', date1: util.firstOfNextMonth, date2: util.lastOfNextMonth },
      { value: 'thisyear', label: 'Este año', date1: util.firstOfYear, date2: util.lastOfYear },
      { value: 'previousyear', label: 'Año pasado', date1: util.firstOfPreviousYear, date2: util.lastOfPreviousYear },
      { value: 'nextyear', label: 'Próximo año', date1: util.firstOfNextYear, date2: util.lastOfNextYear }
    ]
  }

  const handleIntervalChange = useCallback(event => {
    const interval = getIntervalList().filter(interval => interval.value === event.target.value)[0]

    return onChange({
      target: {
        value: event.target.value
      },
      date1Value: interval.date1(),
      date2Value: interval.date2()
    })
  }, [onChange])

  useEffect(() => {
    handleIntervalChange({ target: { value: value } })
  }, [handleIntervalChange, value])

  return (
    <Field.Select
      label={label}
      value={value}
      options={getIntervalList()}
      onChange={handleIntervalChange}
      notnull
      {...props}/>
  )
}

IntervalField.propTypes = {
  /** Showed text next to the field */
  label: PropTypes.string,
  /** Value of the field */
  value: PropTypes.any,
  /** Function triggered when the Input is changed */
  onChange: PropTypes.func
}

IntervalField.defaultProps = {
  label: '',
  value: null,
  onChange: () => {}
}

export default IntervalField
