import { ACL } from 'core/lib'
import PropTypes from 'prop-types'

function Can ({ children, rule }) {
  return ACL.can(rule) && children
}

Can.propTypes = {
  /** Rule for  */
  rule: PropTypes.string.isRequired
}

Can.defaultProps = {}

export default Can
