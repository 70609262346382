import { env } from 'environment'
import { util } from 'quimera'

const apiUrlBase = env.getAPIUrl()

const WSconnect = async (next, channel, dispatch, data, onMessage, onConnect, send, error) => {
  // TODO pasar url como param si no queremos la de apiUrl
  const user = util.getUser()
  // var [requestParams, requestData] = data
  const urlBase = apiUrlBase.startsWith('https') ? 'wss' + apiUrlBase.substring(5, apiUrlBase.length - 4) : 'ws' + apiUrlBase.substring(4, apiUrlBase.length - 4)
  let url = channel ? urlBase + 'room/' + channel : urlBase + 'room/user/' + user.user
  const token = user && user.token ? `?token=${user.token}` : false
  if (token) {
    url += token
  }
  // const socket = new WebSocket(url)
  // socket.onmessage = onMessage
  // socket.onopen = onConnect(socket, next, dispatch)
  // if (socket.readyState === WebSocket.OPEN) {
  //   socket.onopen()
  // }
}

class SocketRunner {
  defOnMessage = (next, dispatch) => res => {
    dispatch({
      type: `on${util.camelId(next)}Recived`,
      payload: res
    })
  }

  defOnConnect = (socket, next, dispatch) => res => {
    const payload = { socket: socket, data: res }
    dispatch({
      type: `on${util.camelId(next)}Connect`,
      payload: payload
    })
  }

  defSend = (next, dispatch) => res => {
    dispatch({
      type: next,
      payload: res
    })
  }

  defError = (next, dispatch) => res => {
    dispatch({
      type: `${next}Failed`,
      payload: res
    })
  }

  connect (next, channel, params, dispatch) {
    const onMessage = this.onMessage ? this.onMessage : this.defOnMessage(next, dispatch)
    const onConnect = this.onConnect ? this.onConnect : this.defOnConnect
    const send = this.send ? this.send : this.defSend(next, dispatch)
    const error = this.error ? this.error : this.defError(next, dispatch)
    WSconnect(next, channel, dispatch, params.extract(), onMessage, onConnect, send, error)
  }
}

class SocketParams {
  filter = null
  extra = {}

  extract () {
    let params = {}
    params = this.filter ? { ...params, filter: JSON.stringify(this.filter) } : params

    return [params, this.extra]
  }
}

class SocketManager {
  constructor (channel) {
    this._channel = channel
    this._params = new SocketParams()
    this._runner = new SocketRunner()
  }

  connect (next, dispatch) {
    this._runner.connect(next, this._channel, this._params, dispatch)
  }

  onMessage (onMessageFunc) {
    this._runner.onMessage = onMessageFunc
    return this
  }

  onConnect (onConnectFunc) {
    this._runner.onConnect = onConnectFunc
    return this
  }

  send (sendObj) {
    this._runner.send = sendObj
    return this
  }

  error (error) {
    this._runner.error = error
    return this
  }
}

export default (model) => new SocketManager(model)
