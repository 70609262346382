import React from 'react'
import TableColumn from 'quimera-comps/Table/TableColumn'

function formatCellValue (value) {
  return value
}

/**
* IntColumn
*/
function IntColumn (props) {
  return (
    <TableColumn columnType="IntColumn" align='right' format={ formatCellValue } {...props} />
  )
}

export default IntColumn
