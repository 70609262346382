import React from 'react'
import PropTypes from 'prop-types'
import { BaseField } from 'quimera-comps'
import CheckBoxGroup from 'quimera-comps/Field/CheckBoxField/CheckBoxGroup'
import CheckBoxField from 'quimera-comps/Field/CheckBoxField/CheckBoxField'

function CheckBoxBase ({ id, options, ...props }) {
  return options ? (
    <BaseField
      id={id}
      Component={
        <CheckBoxGroup id={id} options={options}/>
      }
      {...props}
    />
  ) : (
    <BaseField
      id={id}
      className={''}
      Component={
        <CheckBoxField id={id}/>
      }
      {...props}
    />
  )
}

CheckBoxBase.propTypes = {
  /** Id for reference */
  id: PropTypes.string.isRequired,
  /** Different options of the field */
  options: PropTypes.array
}

CheckBoxBase.defaultProps = {}

export default CheckBoxBase
