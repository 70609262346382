import React from 'react'
import PropTypes from 'prop-types'
import { useStateValue, util } from 'quimera'
import { BaseField } from 'quimera-comps'
import DateFnsUtils from '@date-io/date-fns'
// import esLocale from 'date-fns/locale/es'
import { MuiPickersUtilsProvider } from 'thirdparty'
import DateField from 'quimera-comps/Field/DateField/DateField'

function DateBase({ id, field, index, interval, datePickerProps, ...props }) {
  const [, dispatch] = useStateValue()

  const stateField = field || id

  const handleChange = (event) => {
    dispatch({
      type: `on${util.camelId(id)}Changed`,
      payload: { field: util.lastStateField(stateField), value: event.target.value, index: index }
    })
    interval && dispatch({
      type: `on${util.camelId(interval)}Changed`,
      payload: { field: interval, value: '' }
    })
  }
  const locale = util.getLocaleDateFNS()
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}/* locale={esLocale} */>
      <BaseField
        id={id}
        field={stateField}
        Component={
          <DateField {...datePickerProps} />
        }
        onChange={handleChange}
        {...props}
      />
    </MuiPickersUtilsProvider>
  )
}

DateBase.propTypes = {
  /** Id for reference */
  id: PropTypes.string.isRequired,
  /** Field for state update */
  field: PropTypes.string,
  /** Interval field */
  interval: PropTypes.string,
  /** Props to pass through */
  datePickerProps: PropTypes.object
}

DateBase.defaultProps = {
  datePickerProps: {}
}

export default DateBase
