import React, { useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useStateValue, util } from 'quimera'
import AutocompleteField from 'quimera-comps/Field/AutocompleteField/AutocompleteField'

function SelectField ({ id, field, getOptionLabel, getOptions, value, options, setUniqueOption, timeout, minCharacters, ...props }) {
  const [state, dispatch] = useStateValue()
  const [localValue, setLocalValue] = useState({})
  const [timer, setTimer] = useState(null)

  const stateField = field || id
  const stateValue = value || util.getStateValue(stateField, state, null)

  useEffect(() => {
    stateValue && getOptionLabel && getOptionLabel(stateValue)
  }, [stateValue])

  // useEffect(() => {
  //   setUniqueOption && options.length === 1 && dispatchChange(options[0].key)
  // }, [options])

  useEffect(() => {
    setUniqueOption && options.length === 1 && options[0].key !== localValue.key && dispatchChange(options[0].key)
  }, [options])

  function dispatchChange (localValue) {
    // const actionName = id.replace(/\./g, '')
    console.log('dispatching ', `on${util.camelId(id)}Changed`)
    dispatch({
      type: `on${util.camelId(id)}Changed`,
      payload: { field: util.lastStateField(stateField), value: localValue }
    })
  }

  const handleChange = useCallback((event, valueParam) => {
    dispatchChange(valueParam ? valueParam.key || '' : '')
  }, [id, stateField, dispatch])

  const handleInputChange = useCallback((event) => {
    const newval = event.target.value
    if (stateValue) {
      dispatchChange('')
    }
    clearTimeout(timer)
    setTimer(setTimeout(() => handleUpdate(newval), timeout))
  }, [timer, setTimer, timeout])

  const handleUpdate = useCallback((valueParam) => {
    valueParam.length >= minCharacters && getOptions(valueParam)
  }, [id])

  const valor = options.reduce((acum, o) => o.key === stateValue ? o : acum, {})
  // No debemos cambiar el estado si las opciones no lo contienen, además no sabemos si el estado es un string
  // !valor.key && stateValue && dispatchChange('')
  valor.key !== localValue.key && setLocalValue(valor)

  return (
    <AutocompleteField onChange={ handleChange } onInputChange={ getOptions ? handleInputChange : null } options={ options } value={ localValue } { ...props}/>
  )
}

SelectField.propTypes = {
  /** Id for reference */
  id: PropTypes.string.isRequired,
  /** Field for state update */
  field: PropTypes.string,
  /** Lista de opciones */
  timeout: PropTypes.number,
  minCharacters: PropTypes.number,
  options: PropTypes.array,
  getOptionLabel: PropTypes.func,
  getOptions: PropTypes.func,
  // Indica si hay que seleccionar automáticamente la opción si la lista de opciones solo tiene un elemento
  setUniqueOption: PropTypes.bool,
  value: PropTypes.string
}

SelectField.defaultProps = {
  // setOnlyOption: false,
  setUniqueOption: false,
  minCharacters: 1
}

export default SelectField
