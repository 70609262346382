import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { loadView, getView } from 'core/hooks/useManager'
import { StateProvider, TemplateProvider } from 'core/contexts'
import { env } from 'environment'

/**
*   Lets you create Template components for extension/mix system
*/
function View ({ id, ...props }) {
  const [loaded, setLoaded] = useState(false)
  const [view, setView] = useState([[{}, {}], {}, [], {}])
  const visibleLoading = env.inDevelopment()

  async function initView (id) {
    await loadView(id)
    setView(getView(id))
    setLoaded(true)
  }

  useEffect(() => {
    setLoaded(false)
  }, [id])

  useEffect(() => {
    !loaded && initView(id)
  }, [loaded])

  const [[reducer, initial], apiClient, [View, ...parents], style] = view

  return loaded && View ? (
    <TemplateProvider value={{
      references: [],
      parents: parents
    }}>
      <StateProvider reducer={reducer} apiClient={apiClient} initialState={initial}>
        <View useStyles={style} {...props}/>
      </StateProvider>
    </TemplateProvider>
  ) : visibleLoading && (
    <div>Loading view...</div>
  )
}

View.propTypes = {
  /** Internal id of the view */
  id: PropTypes.string.isRequired
}

View.defaultProps = {}

export default View
