import React from 'react'
import PropTypes from 'prop-types'

/**
*   Lets you create view pieces that will expand or replace other Blocks (No render)
*/
function Reference ({ id, type, children, ...props }) {
  return (
    <React.Fragment/>
  )
}

Reference.propTypes = {
  /** Name of the block to be referenced */
  id: PropTypes.string.isRequired,
  /** Type of expand/replace */
  type: PropTypes.string,
  /** Children elements to pass through */
  children: PropTypes.any
}

Reference.defaultProps = {
  children: []
}

export default Reference
