import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useStateValue, util } from 'quimera'
import IntervalField from 'quimera-comps/Field/IntervalField/IntervalField'

function IntervalBase ({ id, field, date1, date2, ...props }) {
  const [state, dispatch] = useStateValue()

  const stateField = field || id
  const stateValue = util.getStateValue(stateField, state, null)

  const handleChange = useCallback(event => {
    dispatch({
      type: `on${util.camelId(id)}Changed`,
      payload: { field: util.lastStateField(stateField), value: event.target.value }
    })
    dispatch({
      type: `on${util.camelId(date1)}Changed`,
      payload: { field: date1, value: event.date1Value }
    })
    dispatch({
      type: `on${util.camelId(date2)}Changed`,
      payload: { field: date2, value: event.date2Value }
    })
  }, [id, date1, date2, stateField, dispatch])

  const handleFocus = (event) => {
    return true
  }

  const handleBlur = (event) => {
    return true
  }

  return (
    <IntervalField
      id={id}
      value={stateValue}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      {...props}/>
  )
}

IntervalBase.propTypes = {
  /** Id for component instance reference */
  id: PropTypes.string.isRequired,
  /** State field */
  field: PropTypes.string,
  /** Date1 for the interval */
  date1: PropTypes.string,
  /** Date2 for the interval */
  date2: PropTypes.string
}

IntervalBase.defaultProps = {}

export default IntervalBase
