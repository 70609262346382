import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Typography, Button, FormControlLabel, Popover, FormGroup, Checkbox } from 'thirdparty'
import { IconButton, Icon /*, Field */ } from 'quimera-comps'
import { useStyles } from './style'

function PopoverCheckBoxMultiple ({ opcion, handlePopoverClose, anchorEl, idPadre, alAplicar, ...props }) {
  const classes = useStyles()
  // const [fieldText, setFieldText] = useState(opcion.value)
  // const estadoInicial = {}
  // opcion.opciones.map(op => { estadoInicial[op.key] = op.value; console.log('inciochechboxmultiple') })
  const [opciones, setOpciones] = useState(opcion.opciones)
  const [chipValue, setchipValue] = useState(opcion.value)

  function hayAlgunoChecked () {
    let alguno = false
    opciones.map(op => {
      if (op.value === true) { alguno = true }
    })
    // console.log('hayAlgunoChecked alguno', alguno, opciones)
    return alguno
  }

  const [aplicarDisabled, setAplicarDisabled] = useState(hayAlgunoChecked())

  const handleAplicar = () => {
    // alAplicar(valor)
    alAplicar({ opciones: opciones, chipValue: chipValue })
    // console.log('le doy a aplicar: ', opciones, chipValue)
  }

  const cambiarValue = (options) => { // solo seria para mostrarlo bien en el chip
    const value = options.map((op, index) =>
      op.value ? op.valor : ''
    ).filter(op => op !== '').join(', ')
    setchipValue(value)
    // alert(opcion.value)
  }

  const handleChange = (event) => {
    // alert(event.target.name + ' -- ' + event.target.checked)
    // setOpciones({ ...opciones, [event.target.name]: event.target.checked })
    const newOpciones = opciones.slice() // copiamos el estado
    newOpciones[newOpciones.findIndex(op => op.key === event.target.name)].value = event.target.checked
    setOpciones(newOpciones)
    cambiarValue(newOpciones)
    setAplicarDisabled(hayAlgunoChecked())
    // console.log('handleChange aplicarDisabled: ', aplicarDisabled, opciones, newOpciones)
  }

  return (
    <Popover
      id={`${idPadre}${opcion.nombreCampo}Popover`}
      open={Boolean(opcion)}
      anchorEl={anchorEl}
      onClose={handlePopoverClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
      <Box>
        <Box className={classes.cabecera}>
          <Box height="100%" display='flex' alignItems='center' justifyContent="space-between">
            <Box flexGrow={1}><Typography style={{ color: 'white' }}>{` ${opcion.labelNombre} `}</Typography></Box>
            <Box><IconButton size='small' onClick={() => { handlePopoverClose() }}>
              <Icon style={{ color: 'white', fontSize: '1.1rem' }}>close</Icon>
            </IconButton></Box>
          </Box>
        </Box>

        <Box mx={2} display='flex' justifyContent="center" alignItems='center'>
          <FormGroup>
            {opcion.opciones.map(op =>
              <FormControlLabel
                key={op.key} control={<Checkbox checked={opciones.find(opt => op.key === opt.key).value} onClick={handleChange} name={op.key} />}
                label={op.valor}/>
            )}
          </FormGroup>
        </Box>
        <Box mt={1} display='flex' justifyContent="center" alignItems='center'>
          <Button color='primary' variant='contained' className={classes.botonPrimarioSmall} disabled={!aplicarDisabled} onClick={ () => handleAplicar()}>APLICAR</Button>
        </Box>
      </Box>
    </Popover>
  )
}

PopoverCheckBoxMultiple.propTypes = {
  opcion: PropTypes.object,
  handlePopoverClose: PropTypes.any,
  anchorEl: PropTypes.any,
  idPadre: PropTypes.string,
  alAplicar: PropTypes.any
}

PopoverCheckBoxMultiple.defaultProps = {
}

export default PopoverCheckBoxMultiple
