import { API } from 'core/lib'
import { navigate } from 'hookrouter'
import { util } from 'quimera'

export default class BaseApi {
  controller = null

  constructor (c) {
    this.controller = c
  }

  getState () {
    return this.controller.state
  }

  patch (schema, dispatch, state, { key, changes, action = null, files = null, success = null, error = null }) {
    console.log('PATCH CHANGES ', changes)
    let api = API(schema.name)
      .patch(key, action)
      .setKeys(schema.dump ? schema.dump(changes, state) : changes)

    if (files) {
      api = api.setFiles(files)
    }
    if (success) {
      api = api.success(r => success(r))
    }

    if (error) {
      api = api.error(r => error(r))
    }

    api.go(schema.onChanged, dispatch)
  }

  delete (schema, dispatch, { key, changes, action = null, files = null, success = null, error = null }) {
    let api = API(schema.name)
      .delete(key)

    if (success) {
      api = api.success(r => success(r))
    }

    if (error) {
      api = api.error(r => error(r))
    }

    api.go('', dispatch)
  }

  post (schema, dispatch, state, { data, action = null, success = null, error = null }) {
    let api = API(schema.name)
      .post(schema.dump(data, state), action)

    if (success) {
      api = api.success(r => success(r))
    }

    if (error) {
      api = api.error(r => error(r))
    }

    api.go(schema.onCreated, dispatch)
  }

  get (schema, dispatch, { filter = null, pk = null, order = null, page = null, params = null, action = null, success = null, error = null }) {
    let api = API(schema.name)
      .get(pk || null, action || null)
      .select(schema.fields)

    if (filter) {
      api.filter(filter)
    }
    if (success) {
      api.success(r => success({
        ...r,
        data: r.data
          ? Array.isArray(r.data)
            ? r.data.map(d => schema.load(d))
            : schema.load(r.data)
          : null
      }))
    } else {
      api.success(response => {
        const data = response.data.map(d => schema.load(d))
        dispatch({ type: schema.onLoaded, payload: { data: data, schema: schema } })
      })
    }

    if (error) {
      api = api.error(r => error(r))
    }

    if (params) {
      api = api.setKeys(params)
    }
    if (order) {
      api = api.order(`${order.field} ${order.direction}`)
    } else if (schema.order) {
      api = api.order(`${schema.order.field} ${schema.order.direction}`)
    }
    api = api.page(page || schema.page || null)
    api.go(schema.onLoaded, dispatch)
  }

  eat (type, payload, dispatch) {
    console.log('Eating ', type)
    if (!(type in this.bunch)) {
      return
    }
    const actions = this.bunch[type]
    actions.map(a => this.eatGrape(a, payload || {}, dispatch))
  }

  eatGrape (grape, payload, dispatch) {
    const state = this.getState()
    console.log('Grape tipo:', grape.type, 'grape:', grape, 'payload', payload)

    if (grape.condition && !grape.condition(payload, state)) {
      console.log('No se cumple la condicion de grape.condition')
      return
    }

    if (grape.log) {
      console.log(grape.log(payload, state))
    }

    if (grape.type === 'get') {
      console.log('GET', payload, 'result', grape.pk && grape.pk(payload, state))
      if (grape.pk) {
        console.error('Quimera deprecated grape key "pk" for type "get". Use "id" instead')
        grape.id = grape.pk
      }
      this.get(grape.schema, dispatch, {
        filter: grape.filter ? grape.filter(payload, state) : null,
        pk: grape.id ? grape.id(payload, state) : null,
        params: grape.params ? grape.params(payload, state) : null,
        action: grape.action || null,
        order: grape.order ? grape.order(payload, state) : null,
        page: grape.page ? grape.page(payload, state) : null,
        success: response => {
          console.log('Success get', response)
          Array.isArray(grape.success)
            ? grape.success.map(a => this.eatGrape(a, { ...payload, response: response }, dispatch))
            : dispatch({ type: grape.success, payload: { ...payload, response: response } })
        },
        error: response => {
          console.log('Error get', response)
          grape.error
            ? Array.isArray(grape.error)
              ? grape.error.map(a => this.eatGrape(a, { ...payload, response: response }, dispatch))
              : dispatch({ type: grape.error, payload: { ...payload, response: response } })
            : util.appDispatch({ type: 'mostrarMensaje', payload: { mensaje: response, tipoMensaje: 'error' } })
        }
      })
    }

    if (grape.type === 'patch') {
      if (grape.key) {
        console.error('Quimera deprecated grape key "key" for type "patch". Use "id" instead')
        grape.id = grape.key
      }
      this.patch(grape.schema, dispatch, state, {
        key: grape.id(payload, state),
        changes: grape.data ? grape.data(payload, state) : {},
        action: grape.action || null,
        files: grape.files && grape.files(payload, state),
        success: response => {
          const responsePayload = {}
          responsePayload[grape.responseKey || 'response'] = response
          console.log('Success patch', response)
          Array.isArray(grape.success)
            ? grape.success.map(a => this.eatGrape(a, { ...payload, ...responsePayload }, dispatch))
            : dispatch({ type: grape.success, payload: { ...payload, ...responsePayload } })
        },
        error: response => {
          console.log('Error patch', response)
          grape.error
            ? Array.isArray(grape.error)
              ? grape.error.map(a => this.eatGrape(a, { ...payload, response: response }, dispatch))
              : dispatch({ type: grape.error, payload: { ...payload, response: response } })
            : util.appDispatch({ type: 'mostrarMensaje', payload: { mensaje: response, tipoMensaje: 'error' } })
        }
      })
    }

    if (grape.type === 'post') {
      this.post(grape.schema, dispatch, state, {
        data: grape.data(payload, state),
        action: grape.action || null,
        success: response => {
          console.log('Success post', response)
          Array.isArray(grape.success)
            ? grape.success.map(a => this.eatGrape(a, { ...payload, response: response }, dispatch))
            : dispatch({ type: grape.success, payload: { ...payload, response: response } })
        },
        error: response => {
          console.log('Error post', response)
          grape.error
            ? Array.isArray(grape.error)
              ? grape.error.map(a => this.eatGrape(a, { ...payload, response: response }, dispatch))
              : dispatch({ type: grape.error, payload: { ...payload, response: response } })
            : util.appDispatch({ type: 'mostrarMensaje', payload: { mensaje: response, tipoMensaje: 'error' } })
        }
      })
    }

    if (grape.type === 'delete') {
      if (grape.key) {
        console.error('Quimera deprecated grape key "key" for type "delete". Use "id" instead')
        grape.id = grape.key
      }
      const payloadNuevo = grape.plug ? grape.plug(payload, state) : payload
      this.delete(grape.schema, dispatch, {
        key: grape.id && grape.id(payload, state),
        success: response => {
          console.log('Success delete', response)
          Array.isArray(grape.success)
            ? grape.success.map(a => this.eatGrape(a, { ...payload, response: response }, dispatch))
            : dispatch({ type: grape.success, payload: { ...payloadNuevo, response: response } })
        },
        error: response => {
          console.log('Error delete', response)
          grape.error
            ? Array.isArray(grape.error)
              ? grape.error.map(a => this.eatGrape(a, { ...payload, response: response }, dispatch))
              : dispatch({ type: grape.error, payload: { ...payload, response: response } })
            : util.appDispatch({ type: 'mostrarMensaje', payload: { mensaje: response, tipoMensaje: 'error' } })
        }
      })
    }

    if (grape.type === 'download') {
      let api = API(grape.schema.name)
        .get(grape.pk && grape.pk(payload, state), grape.action)

      console.log('Params?', grape.params)
      if (grape.params) {
        api = api.setKeys(grape.params(payload, state))
      }
      api.download('', grape.fileName(payload, state), dispatch)
    }

    if (grape.type === 'dispatch') {
      dispatch({ type: typeof grape.name === 'string' ? grape.name : grape.name(payload, state), payload: grape.plug ? grape.plug(payload, state) : payload.response })
    }

    if (grape.type === 'setStateKey') {
      this.controller.setStateKey(state, grape.plug ? grape.plug(payload, state) : payload.response)
    }
    if (grape.type === 'setStateKeys') {
      this.controller.setStateKeys(state, grape.plug ? grape.plug(payload, state) : payload.response)
    }

    if (grape.type === 'appDispatch') {
      if (grape.name === 'setNombrePaginaActual') {
        console.error('Quimera deprecated grape key "setNombrePaginaActual" for type "appDispatch". Use "setPageName" type instead')
      }
      if (grape.name === 'mostrarMensaje') {
        console.error('Quimera deprecated grape key "mostrarMensaje" for type "appDispatch". Use "showMessage" type instead')
      }
      util.appDispatch({ type: grape.name, payload: grape.plug ? grape.plug(payload, state) : payload.response })
    }

    if (grape.type === 'setPageName') {
      util.appDispatch({ type: 'setNombrePaginaActual', payload: grape.plug ? grape.plug(payload, state) : payload.response })
    }

    if (grape.type === 'showMessage') {
      util.appDispatch({ type: 'mostrarMensaje', payload: grape.plug ? grape.plug(payload, state) : payload.response })
    }

    if (grape.type === 'function') {
      if (!grape.function) {
        console.log('Has creado un grape de tipo function sin valores para function')
        return
      }
      const response = grape.plug ? grape.function(grape.plug(payload, state)) : grape.function(payload, state)
      console.log('Success function', response)
      grape.success && grape.success.map(a => this.eatGrape(a, { ...payload, response: response }, dispatch))
    }

    if (grape.type === 'navigate') {
      if (!grape.url) {
        console.log('Has creado un grape de tipo navigate sin valores para url')
        return
      }
      navigate(grape.url(payload, state))
    }

    if (grape.type === 'navigateOut') {
      if (!grape.url) {
        console.log('Has creado un grape de tipo navigateOut sin valores para url')
        return
      }
      window.location.assign(grape.url(payload, state))
    }

    if (grape.type === 'newtab') {
      if (!grape.url) {
        console.log('Has creado un grape de tipo newtab sin valores para url')
        return
      }
      window.open(grape.url(payload, state), '_blank')
    }

    if (grape.type === 'userConfirm') {
      if (!grape.question) {
        console.log('Has creado un grape de tipo userConfirm sin valores para question')
        return
      }
      if (!grape.onConfirm) {
        console.log('Has creado un grape de tipo userConfirm sin valores para onConfirm')
        return
      }
      util.getSetting('appDispatch')({
        type: 'invocarConfirm',
        payload: {
          ...grape.question,
          alConfirmar: () => dispatch({ type: grape.onConfirm, payload: grape.plug ? grape.plug(payload, state) : { ...payload } })
        }
      })
    }

    if (grape.type === 'regrape' || grape.type === 'grape') {
      if (grape.type === 'regrape') {
        console.error('Quimera deprecated grape type "regrape". Use grape instead')
      }
      if (!grape.grape && !grape.name) {
        console.log('Has creado un grape de tipo grape sin valores para name')
        return
      }
      if (grape.grape) {
        console.error('Quimera deprecated grape attribute "grape" for type "grape". Use "name" instead')
        grape.name = grape.grape
      }
      this.eat(grape.name, grape.plug ? grape.plug(payload, state) : payload, dispatch)
    }
  }
}
