import { util } from 'quimera'

const templates = {
  masterCtrl: (schema) => ({
    list: [],
    page: {
      limit: schema.limit || 50,
      next: null,
      previous: null
    },
    loading: true,
    filter: {},
    order: schema.order() || null
  }),
  detailCtrl: schema => schema.load({}),
  masterApi: ({ name, table, schema }) => ({
    [`onNext${util.camelId(name)}`]: [
      {
        type: 'regrape',
        grape: `get${util.camelId(name)}`,
        plug: () => ({ page: 'next' })
      }
    ],
    [`on${util.camelId(name)}FilterChanged`]: [
      {
        type: 'dispatch',
        name: 'setStateKey',
        plug: ({ value }) => ({ path: `${name}.filter`, value: value })
      },
      {
        type: 'regrape',
        grape: `get${util.camelId(name)}`
      }
    ],
    [`on${util.camelId(table)}ColumnClicked`]: [
      {
        type: 'dispatch',
        name: 'setStateKey',
        plug: ({ data }, state) => ({ path: `${name}.order`, value: { field: data.id, direction: state[name].order && state[name].order.field === data.id && state[name].order.direction === 'ASC' ? 'DESC' : 'ASC' } })
      },
      {
        type: 'regrape',
        grape: `get${util.camelId(name)}`
      }
    ],
    [`get${util.camelId(name)}`]: [
      {
        type: 'dispatch',
        name: 'setStateKey',
        plug: () => ({ path: `${name}.loading`, value: true })
      },
      {
        type: 'get',
        schema: schema,
        filter: (...[, state]) => ({ and: [schema.filter ? schema.filter(state) : [], ...state[name].filter.and || []] }),
        page: ({ page }, state) => page === 'next' ? { limit: state[name].page.limit, next: state[name].page.next } : { limit: state[name].page.limit },
        order: (...[, state]) => state[name].order ? state[name].order : schema.order ? schema.order(state) : null,
        success: [
          {
            type: 'dispatch',
            name: 'setStateKey',
            plug: ({ response, page }, state) => ({
              path: name,
              value: {
                ...state[name],
                list: page === 'next' ? [...state[name].list, ...response.data] : response.data,
                page: response.page,
                loading: false
              }
            })
          },
          {
            type: 'regrape',
            grape: `onGet${util.camelId(name)}Succeded`
          }
        ]
      }
    ]
  }),
  detailApi: ({ key, name, schema }) => ({
    [`get${util.camelId(name)}`]: [
      {
        type: 'get',
        schema: schema,
        pk: (...[, state]) => state[key],
        success: [
          {
            type: 'dispatch',
            name: 'setStateKey',
            plug: ({ response }) => ({ path: name, value: response.data[0] })
          },
          {
            type: 'regrape',
            grape: `onGet${util.camelId(name)}Succeded`
          }
        ]
      }
    ],
    [`onSave${util.camelId(name)}Clicked`]: [
      {
        type: 'function',
        function: (...[, state]) => schema.validation ? schema.validation(state[name]) : { isFormValid: true },
        success: [
          {
            condition: ({ response }) => response.isFormValid,
            type: 'patch',
            schema: schema,
            key: (...[, state]) => state[key],
            data: (...[, state]) => state[name],
            success: [
              {
                type: 'regrape',
                grape: `get${util.camelId(name)}`
              },
              {
                type: 'regrape',
                grape: `onSave${util.camelId(name)}Succeded`
              }
            ]
          },
          {
            condition: ({ response }) => !response.isFormValid,
            type: 'regrape',
            grape: `onSave${util.camelId(name)}Failed`
          }
        ]
      }
    ],
    [`onCancel${util.camelId(name)}Clicked`]: [
      {
        type: 'regrape',
        grape: `get${util.camelId(name)}`
      }
    ]
  }),
  newApi: ({ name, schema }) => ({
    [`onSave${util.camelId(name)}Clicked`]: [
      {
        type: 'function',
        function: (...[, state]) => schema.validation ? schema.validation(state[name]) : { isFormValid: true },
        success: [
          {
            condition: ({ response }) => response.isFormValid,
            type: 'post',
            schema: schema,
            data: (...[, state]) => state[name],
            success: [
              {
                type: 'regrape',
                grape: `cleanUp${util.camelId(name)}`
              },
              {
                type: 'regrape',
                grape: `onSave${util.camelId(name)}Succeded`
              }
            ]
          },
          {
            condition: ({ response }) => !response.isFormValid,
            type: 'regrape',
            grape: `onSave${util.camelId(name)}Failed`
          }
        ]
      }
    ],
    [`onCancel${util.camelId(name)}Clicked`]: [
      {
        type: 'regrape',
        grape: `cleanUp${util.camelId(name)}`
      }
    ],
    [`cleanUp${util.camelId(name)}`]: [
      {
        type: 'dispatch',
        name: 'setStateKey',
        plug: () => ({ path: name, value: schema.load({}) })
      }
    ]
  })
}
export default templates
