import React from 'react'
import PropTypes from 'prop-types'
import { useStateValue, util } from 'quimera'
import { Button as ButtonMUI, Tooltip } from 'thirdparty'

const Button = React.forwardRef(function Button (bProps, ref) {
  const { children, id, text, data, tooltip, ...props } = bProps
  // function Button ({ children, id, text, data, ...props }) {
  const [, dispatch] = useStateValue()

  const handleClick = (e) => {
    dispatch({
      type: `on${util.camelId(id)}Clicked`,
      payload: {
        data: data,
        event: e
      }
    })
  }

  const b = <ButtonMUI
    id={id}
    onClick={handleClick}
    {...props}
    ref={ref}
  >
    { text }
    { children }
  </ButtonMUI>

  // El span garantiza que el tooltip se verá aunque el botón esté inhabilitado
  return (
    tooltip
      ? <Tooltip title={tooltip} >
        <span>
          { b }
        </span>
      </Tooltip>
      : b
  )
})

Button.propTypes = {
  /** Id for reference */
  id: PropTypes.string.isRequired,
  /** Id for reference */
  children: PropTypes.node,
  /** Text to show inside the button */
  text: PropTypes.any,
  /** Data pass through the dispatch */
  data: PropTypes.any,
}

Button.defaultProps = {
  data: null
}

export default Button
