import React from 'react'
import PropTypes from 'prop-types'
import { useTemplateValue } from 'core/hooks'

/**
*   Lets you create view pieces that can be expanded or replaced
*/
function Block ({ id, Component, componentProps, children }) {
  const template = useTemplateValue()
  const blockReferences = template.references.filter(ref => ref.props.id === id)

  const compProps = Component !== React.Fragment ? { ...componentProps, id } : { key: id }

  const newChildren = [].concat(
    blockReferences.reduce((arr, item) => item.props.type === 'prepend' ? arr.concat(item.props.children) : arr, [])
  ).concat(
    children
  ).concat(
    blockReferences.reduce((arr, item) => item.props.type === 'append' ? arr.concat(item.props.children) : arr, [])
  )

  blockReferences.filter(item => item.props.type === 'insert-before').map(item => {
    const idx = newChildren.indexOf(newChildren.filter(child => child.props.id === item.props.refid)[0])
    if (idx > -1) {
      newChildren.splice(idx, 0, item.props.children)
    } else {
      console.error(`Se está intentando insertar una referencia antes del elemento con id '${item.props.refid}', pero este no existe`)
    }
  })

  blockReferences.filter(item => item.props.type === 'insert-after').map(item => {
    const idx = newChildren.indexOf(newChildren.filter(child => child.props.id === item.props.refid)[0])
    if (idx > -1) {
      newChildren.splice(idx + 1, 0, item.props.children)
    } else {
      console.error(`Se está intentando insertar una referencia después del elemento con id '${item.props.refid}', pero este no existe`)
    }
  })

  return (
    blockReferences.filter(ref => ref.props.type === 'delete').length ? '' : (
      <Component {...compProps}>
        { newChildren }
      </Component>
    )
  )
}

Block.propTypes = {
  /** Id of the block to be referenced */
  id: PropTypes.string.isRequired,
  /** Component wrapper to render */
  Component: PropTypes.any,
  /** Component props to pass through */
  componentProps: PropTypes.object,
  /** Children elements to pass through */
  children: PropTypes.any
}

Block.defaultProps = {
  Component: React.Fragment,
  componentProps: {},
  children: []
}

export default Block
