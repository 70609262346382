import React, { useState, useEffect } from 'react'

import { env } from 'environment'
import PropTypes from 'prop-types'
import { AppProvider } from 'core/contexts'
import { getRoutes, getTheme, loadProject, loadView, getView, buildView } from 'core/hooks/useManager'
import { createMuiTheme, ThemeProvider } from 'quimera-styles'
import { Box, Icon } from 'quimera-comps'

export default function App() {
  const [loading, setLoading] = useState(true)
  const [authenticated, setAuthenticated] = useState(false)
  // console.log('mimensaje24', window.location.pathname.includes("login"))
  const pathname = window.location.pathname
  const hash = pathname.includes("login") ? pathname.replace("login", "").replaceAll("/", "") : ''
  const isDev = false
  const needsLogin = !loading && !authenticated && '/login' in getRoutes() && !isDev
  const Login = needsLogin ? buildView('Login', { onLogin: user => setAuthenticated(true), hash: hash }) : null

  const [[appReducer, appInitialState], appApiClient/* , ...rest */] = loading ? [[{}, {}], {}, [], {}] : getView('Container')
  const Container = loading ? null : buildView('Container')

  const MUItheme = loading ? null : createMuiTheme(getTheme())
  const title = env.getCurrentTitle() || 'Quimera'
  const visibleLoading = env.inDevelopment() ? 'visible' : 'hidden'

  // console.log('PROJECT', env.getCurrentProject())
  async function loadApp() {
    await loadProject(env.getCurrentProject())
    await loadView('Container')
    setLoading(false)
  }

  // const { init, UseGTMHookProvider } = useGTM()
  // const GTMId = env.GTMKey()
  // // GTM-P67HFQT para app
  // // GTM-MPJ2L2T para demo
  // useEffect(() => init({ id: 'GTM-P67HFQT' }), [])

  useEffect(() => {
    loadApp()
  }, [])

  class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props)
      this.state = { error: null, errorInfo: null }
    }

    componentDidCatch(error, errorInfo) {
      this.setState({
        error: error,
        errorInfo: errorInfo
      })
    }

    render() {
      if (this.state.errorInfo) {
        return (
          <Box display='flex' flexDirection='column' width={1} alignItems='center' justifyContent='center' mt={8}>
            <img src='/static/images/dailyjob-completo-transparente.png' alt='Logo Dailyjob' width='150' />
            <Box m={1} display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
              <h2>Algo no ha funcionado correctamente</h2>
              <Icon sli style={{fontSize: '50px'}} >error</Icon>
            </Box>
            <Box m={1} display='flex' alignItems='flex-start'>
              <a href='/'><h3>Volver</h3></a>
            </Box>
            <Box m={2}>
              <details style={{ whiteSpace: 'pre-wrap' }}>
                {this.state.error && this.state.error.toString()}
                <br />
                {this.state.errorInfo.componentStack}
              </details>
            </Box>
          </Box>
        )
      }
      // Render children if there's no error
      return this.props.children
    }
  }

  ErrorBoundary.propTypes = {
    children: PropTypes.any
  }

  return loading ? (
    <div style={{ visibility: visibleLoading }}>Loading app</div>
  ) : (
    <ThemeProvider theme={MUItheme}>
      <title>{title}</title>
      <AppProvider reducer={appReducer} apiClient={appApiClient} initialState={appInitialState}>
        {
          <ErrorBoundary>
            {Login || Container}
          </ErrorBoundary>
        }
      </AppProvider>
    </ThemeProvider>
  )
}
