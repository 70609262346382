import React, { useState, useEffect } from 'react'
import { useStyles } from './style'
import { API } from 'core/lib'
import PropTypes from 'prop-types'
import { Box, Typography, Button, Autocomplete, Popover } from 'thirdparty'
import { IconButton, Icon, BaseField /*, Field */ } from 'quimera-comps'

function PopoverAPIField ({ opcion, handlePopoverClose, anchorEl, idPadre, alAplicar, componente, ...props }) {
  console.log('Opcion API', opcion)
  const classes = useStyles()
  const [options, setOptions] = useState([])
  const [value, setValue] = useState(opcion.value)
  // console.log('El value es_ ', value, 'recibido en_ ', opcion.value, 'opcion: ', opcion)
  const handleAplicar = () => {
    // const valor = document.getElementById(`${idPadre}${opcion.nombreCampo}Field1`).value
    alAplicar(value)
    // console.log('APIFIELD', value)
  }

  function handleChange (event, value) {
    value === null ? setValue({ key: '', value: '' }) : setValue(value)
  }

  function getOptions (val) {
    console.log('getOptions')
    if (opcion.componente) {
      return
    }
    console.log('no hay componente', componente)
    let filtro = [opcion.buscarPor, 'like', val]
    if (opcion.filtroAPI) {
      filtro = {
        and: [
          [opcion.buscarPor, 'like', val],
          opcion.filtroAPI
        ]
      }
    }
    API(opcion.tablaAPI)
      .get()
      .select(opcion.selectAPI)
      // .filter([opcion.buscarPor, 'like', val])
      .filter(filtro)
      .page({ limit: 15 })
      .success(response => setOptions(response.data.map(registro => {
        const keyACoger = opcion.keyACoger || opcion.nombreCampo
        return { key: registro[keyACoger], value: opcion.tablaAPI === 'gt_proyectos' ? (registro.aliascliente ? ('#' + registro.aliascliente + ' ') : '') + registro[opcion.buscarPor] : registro[opcion.buscarPor] }
      })))
      .error(error => console.log('Error', error))
      .go()
  }

  useEffect(() => { // para la primera vez
    if (opcion.componente) {
      return
    }
    getOptions('')
  }, [opcion, componente])

  useEffect(() => {
    if (opcion.componente) {
      return
    }
    if (value && value.value === '' && value.key !== '') {
      // console.log('he entrado en el if para recuperar el nombre dado el id')
      API(opcion.tablaAPI)
        .get()
        .select(opcion.selectAPI)
        .filter([opcion.nombreCampo, 'eq', value.key])
        .success(response => setValue(response.data.map(registro => { return { key: registro[opcion.nombreCampo], value: registro[opcion.buscarPor] } })[0]))
        .error(error => console.log('Error', error))
        .go()
    }
  }, [options, value])

  return (
    <Popover
      id={`${idPadre}${opcion.nombreCampo}Popover`}
      open={Boolean(opcion)}
      anchorEl={anchorEl}
      onClose={handlePopoverClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
      <Box>
        <Box className={classes.cabecera}>
          <Box height="100%" display='flex' alignItems='center' justifyContent="space-between">
            <Box flexGrow={1}><Typography style={{ color: 'white' }}>{` ${opcion.labelNombre} `}</Typography></Box>
            <Box><IconButton size='small' onClick={() => { handlePopoverClose() }}>
              <Icon style={{ color: 'white', fontSize: '1.1rem' }}>close</Icon>
            </IconButton></Box>
          </Box>
        </Box>
        <Box mx={1} mt={1} style={{ width: '280px' }} display='flex' flexGrow={1} >
          <Box width={1} >
            { opcion.componente
              ? opcion.componente(handleChange, value.key)
              : <Autocomplete style={{ width: '280px' }}
                getOptionLabel={(option) => option.value}
                // onChange={(event, value) => value === null ? setValue({ key: '', value: '' }) : setValue(value)}
                onChange={handleChange}
                value={value} options={options} // setUniqueOption
                renderInput={(params) => (
                  <BaseField
                    onChange={event => getOptions(event.target.value)}
                    label="Busca" variant="standard" {...params} fullWidth/>
                )}
                noOptionsText=''
              />
            }
          </Box>
        </Box>
        <Box mt={1} display='flex' justifyContent="center" alignItems='center'>
          <Button color='primary' variant='contained' className={classes.botonPrimarioSmall} disabled={value?.key === ''} onClick={ () => handleAplicar()}>APLICAR</Button>
        </Box>
      </Box>
    </Popover>
  )
}

PopoverAPIField.propTypes = {
  opcion: PropTypes.object,
  handlePopoverClose: PropTypes.any,
  anchorEl: PropTypes.any,
  idPadre: PropTypes.string,
  alAplicar: PropTypes.any,
  componente: PropTypes.func
}

PopoverAPIField.defaultProps = {
}

export default PopoverAPIField
