import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'quimera-comps'

function TextArea ({ rows, ...props }) {
  return (
    <Field.Text
      multiline
      rows={rows}
      {...props}/>
  )
}

TextArea.propTypes = {
  /** Number of rows to render */
  rows: PropTypes.string
}

TextArea.defaultProps = {
  rows: '4'
}

export default TextArea
