import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Typography, Button, Popover, FormControlLabel, Switch } from 'thirdparty'
import { IconButton, Icon } from 'quimera-comps'
import { useStyles } from './style'

function PopoverBoolean ({ opcion, handlePopoverClose, anchorEl, idPadre, alAplicar, ...props }) {
  const classes = useStyles()
  const [checked, setChecked] = useState(opcion.value === '' ? false : opcion.value)

  const handleAplicar = () => {
    // const valor = document.getElementById(`${idPadre}${opcion.nombreCampo}Field1`).value
    alAplicar(checked)
  }

  return (
    <Popover
      id={`${idPadre}${opcion.nombreCampo}Popover`}
      open={Boolean(opcion)}
      anchorEl={anchorEl}
      onClose={handlePopoverClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
      <Box>
        <Box className={classes.cabecera}>
          <Box height="100%" display='flex' alignItems='center' justifyContent="space-between">
            <Box flexGrow={1}><Typography style={{ color: 'white' }}>{` ${opcion.labelNombre} `}</Typography></Box>
            <Box><IconButton size='small' onClick={() => { handlePopoverClose() }}>
              <Icon style={{ color: 'white', fontSize: '1.1rem' }}>close</Icon>
            </IconButton></Box>
          </Box>
        </Box>
        <Box mx={2} display='flex' justifyContent="center" alignItems='center'>
          <FormControlLabel autoFocus labelPlacement="start" control={<Switch checked={ Boolean(checked) } onChange={(e) => setChecked(e.target.checked)}/>} label={opcion.labelChip} />
        </Box>
        <Box mt={1} display='flex' justifyContent="center" alignItems='center'>
          <Button color='primary' variant='contained' className={classes.botonPrimarioSmall} disabled={checked === null } onClick={ () => handleAplicar()}>APLICAR</Button>
        </Box>
      </Box>
    </Popover>
  )
}

PopoverBoolean.propTypes = {
  opcion: PropTypes.object,
  handlePopoverClose: PropTypes.any,
  anchorEl: PropTypes.any,
  idPadre: PropTypes.string,
  alAplicar: PropTypes.any
}

PopoverBoolean.defaultProps = {
}

export default PopoverBoolean
