import React from 'react'
import { makeStyles } from 'quimera-styles'
import Quimera from 'quimera'
import PropTypes from 'prop-types'
import { BaseApi, BaseController } from 'core/base'

const mgr = {
  views: {},
  subviews: {},
  project: null
}

async function getDependencies (deps, dict) {
  const cleanDeps = deps.filter(name => !(name in dict))
  if (cleanDeps.length === 0) {
    return
  }
  const depModules = await Promise.all(cleanDeps.map(m => import(`../../${m}`)))
  for (let i = 0; i < cleanDeps.length; i++) {
    const d = depModules[i].default
    const depId = cleanDeps[i]
    d.id = depId
    dict[depId] = d
    if ('dependencies' in d) {
      await getDependencies(d.dependencies, dict)
    }
  }
}

function getOrderedDependencies (deps, dict, alreadyIn) {
  const orderedDeps = []
  const filteredDeps = deps.filter(depId => !(depId in alreadyIn))
  for (var i = 0; i < filteredDeps.length; i++) {
    const dep = dict[filteredDeps[i]]
    alreadyIn[dep.id] = true
    if ('dependencies' in dep) {
      orderedDeps.push(...getOrderedDependencies(dep.dependencies, dict, alreadyIn))
    }
    orderedDeps.push(dep)
  }
  return orderedDeps
}

export async function loadProject (project) {
  const p = (await import(`../../projects/${project}`)).default
  const depsDict = {}
  await getDependencies(p.dependencies, depsDict)
  const depObjects = getOrderedDependencies(p.dependencies, depsDict, {})
  const themePath = p.theme

  mgr.project = {
    views: depObjects.map(m => async function (name) {
      return m.views.indexOf(name) >= 0 ? import(`../../${m.path}/views/${name}`) : null
    }),
    subviews: depObjects.map(m => async function (name) {
      return m.subviews.indexOf(name) >= 0 ? import(`../../${m.path}/views/${name}`) : null
    }),
    dependencies: depObjects,
    routes: depObjects.map(m => m.routes),
    rules: depObjects.map(m => m.rules),
    rulesByComplement: depObjects.map(m => m.rulesByComplement),
    theme: themePath ? (await import(`../../${themePath}`)).default : {}
  }
}

function setView (name, view) {
  mgr.views[name] = view
}
let cointainerView = null

export function getView (name) {
  console.log('getView', name)
  if (!(name in mgr.views)) {
    return [[{}, {}], {}, [], {}]
  }

  if (name === 'Container' && cointainerView) {
    return cointainerView
  }  

  const [initialState, ControllerClass, ApiClass, ui, style] = mgr.views[name]
  const myInitialState = { ...initialState }
  const controller = new ControllerClass(myInitialState)
  const ctrl = [
    getReducer(controller),
    myInitialState
  ]
  const api = new ApiClass(controller)
  const apiMiddleware = getApiMiddleware(api)

  if (name === 'Container') {
    cointainerView = [ctrl, apiMiddleware, ui, style]
  }
  return [ctrl, apiMiddleware, ui, style]
}

function setSubView (name, subview) {
  mgr.subviews[name] = subview
}

const CreateView = ({ id, ...props }) => <Quimera.View id={id} {...props}/>
CreateView.propTypes = {
  id: PropTypes.string.isRequired
}

const cachedViews = {}
export function buildView (id, props) {
  if (!(id in cachedViews)) {
    cachedViews[id] = CreateView
  }
  const View = cachedViews[id]
  return <View id={id} {...props}/>
}

const getReducer = (controller) => (state, action) => {
  var fn = controller[action.type]
  if (fn) {
    return controller[action.type](state, action.payload)
  } else if (action.type.startsWith('on') && action.type.endsWith('Changed')) {
    const update = (state, keys) => {
      if (!keys.length) {
        return action.payload.value
      }
      const [key, ...rest] = keys
      return {
        ...state,
        [key]: update(state[key], rest)
      }
    }
    return controller.setState(update(controller.state, action.payload.field.split('.')))
  } else {
    console.log(action.type, ' no encontrada, devolviendo estado de memoria ', controller.state)
    return controller.state
  }
}

const getApiMiddleware = (api) => (action, dispatch) => {
  var fn = api[action.type]
  if (fn) {
    api[action.type](action.payload || {}, dispatch)
  } else {
    if (api.bunch) {
      api.eat(action.type, action.payload, dispatch)
    }
  }
}

export async function loadView (name) {
  if (name in mgr.views) {
    return
  }

  const viewList = await Promise.all(mgr.project.views.map(m => m(name)))

  const ctrls = viewList.filter(m => m != null && m.ctrl != null).map(m => m.ctrl)
  const states = viewList.filter(m => m != null && m.state != null).map(m => m.state)
  const apis = viewList.filter(m => m != null && m.api != null).map(m => m.api)
  const uis = viewList.filter(m => m != null && m.ui != null).map(m => m.ui)
  const styles = viewList.filter(m => m != null && m.style != null).map(m => m.style)

  const initialState = states.reduce((fun, item) => item(fun), () => {})
  const ControllerClass = ctrls.reduce((fun, item) => item(fun), BaseController)

  const ApiClass = apis.reduce((fun, item) => item(fun), BaseApi)
  const ui = [uis.pop()]
  const style = makeStyles(styles.reduce((fun, item) => item(fun), () => {}))

  setView(name, [initialState, ControllerClass, ApiClass, ui, style])
}

export async function loadSubView (name) {
  if (name in mgr.subviews) {
    return
  }

  const subviewList = await Promise.all(mgr.project.subviews.map(m => m(name)))

  const uis = subviewList.filter(m => m != null && m.ui != null).map(m => m.ui)
  const styles = subviewList.filter(m => m != null && m.style != null).map(m => m.style)
  // const ui = uis.reverse()
  const ui = [uis.pop()]
  const style = makeStyles(styles.reduce((fun, item) => item(fun), () => {}))

  setSubView(name, [null, null, ui, style])
}

export function getRoutes () {
  return mgr.project.routes.reduce((obj, route) => { return { ...obj, ...route } }, {})
}

export function getRules () {
  return mgr.project.rules.reduce((obj, rule) => { return { ...obj, ...rule } }, { access: true })
}

export function getRulesByComplement () {
  return mgr.project.rulesByComplement.reduce((obj, rule) => { return { ...obj, ...rule } }, { access: true })
}

export function getTheme () {
  return mgr.project.theme
}

export function getMgr () {
  return mgr
}

export function useSubView (name) {
  if (!(name in mgr.subviews)) {
    return [[{}, {}], {}, [], {}]
  }

  return mgr.subviews[name]
}

export function useView (name) {
  return getView(name)
}
