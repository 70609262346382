import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useStateValue, util } from 'quimera'
import { InputAdornment } from 'quimera-comps'
import { makeStyles } from 'quimera-styles'
import { Box, InputBase, TextField, FormHelperText } from 'thirdparty'

const useStyles = makeStyles(theme => ({
  root: null,
  overrides: theme.overrides // theme.textField
}))

function BaseField ({
  id,
  index,
  field,
  value,
  formatter,
  preFormat,
  Component,
  startAdornment,
  endAdornment,
  InputProps,
  naked,
  updateTime,
  error,
  helperText,
  ...props
}) {
  const classes = useStyles()
  const [state, dispatch] = useStateValue()
  const [timer, setTimer] = useState()

  const stateField = field || id
  console.log('VALUE', index, value, (value || value === ''))
  let stateValue = (value || value === '' || value === null) ? value : util.getStateValue(stateField, state, value)
  console.log('stateValue', stateValue)
  if (preFormat) {
    stateValue = preFormat(stateValue)
  }
  // ? value : util.getStateValue(stateField, state, value)

  const handleChange = (e) => {
    if (!stateField || stateField === '') {
      return
    }
    dispatch({
      type: `on${util.camelId(id)}Changed`,
      payload: { field: util.lastStateField(stateField), value: formatter(e.target.value), index: index }
    })

    if (updateTime) {
      const newval = e.target.value
      clearTimeout(timer)
      setTimer(setTimeout(() => dispatch({
        type: `on${util.camelId(id)}Timeout`,
        payload: { field: util.lastStateField(stateField), value: formatter(newval) }
      }), updateTime))
    }
  }

  // const handleInputChange = useCallback((event) => {
  //   const newval = event.target.value
  //   clearTimeout(timer)
  //   setTimer(setTimeout(() => handleUpdate(newval), timeout))
  // }, [timer, setTimer, timeout])

  const handleFocus = (e) => {}
  const handleBlur = (e) => {
    if (!stateField || stateField === '') {
      return
    }

    dispatch({
      type: `on${util.camelId(id)}Blurred`,
      payload: { field: util.lastStateField(stateField), value: formatter(e.target.value), index: index }
    })
  }

  const inputProps = {
    startAdornment: startAdornment ? (
      <InputAdornment position='start'>
        { startAdornment }
      </InputAdornment>
    ) : null,
    endAdornment: endAdornment ? (
      <InputAdornment position='end'>
        { endAdornment }
      </InputAdornment>
    ) : null,
    // style: { fontSize: 16 },
    ...InputProps
  }
  // console.log('baseField ' + id + ' stateValue = ' + stateValue)

  return Component ? (
    <Box width={1}>
      { React.cloneElement(Component, { id: id || stateField, value: stateValue, onChange: handleChange, onFocus: handleFocus, onBlur: handleBlur, className: classes.root, ...props })
      }
      { helperText &&
        <FormHelperText error={error}>{helperText}</FormHelperText>
      }
    </Box>
  ) : naked
    ? <Box width={1}>
      <InputBase
        id={id || stateField}
        className={classes.root}
        margin='dense'
        value={stateValue}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        {...props}/>
      { helperText &&
        <FormHelperText error={error}>{helperText}</FormHelperText>
      }
    </Box>
    : <TextField
      id={id || stateField}
      className={classes.root}
      margin='dense'
      value={stateValue}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      InputProps={inputProps}
      error={error}
      helperText={helperText}
      {...props}/>
}

BaseField.propTypes = {
  /** Id for reference */
  id: PropTypes.string,
  /** Field for state update */
  field: PropTypes.string,
  /** Value of the field */
  value: PropTypes.any,
  /** Formatter func to return formatted value */
  formatter: PropTypes.func,
  /** PreFormat func to return formatted value */
  preFormat: PropTypes.func,
  /** Component that will render */
  Component: PropTypes.any,
  /** Adornment for the field */
  startAdornment: PropTypes.any,
  /** Adornment for the field */
  endAdornment: PropTypes.any,
  /** Props passed through */
  InputProps: PropTypes.object,
  /** Field with no label nor lines */
  naked: PropTypes.bool
}

BaseField.defaultProps = {
  formatter: value => value,
  naked: false
}

export default BaseField
