import React, { createContext, useReducer, useCallback } from 'react'
import PropTypes from 'prop-types'

export const AppContext = createContext()

function AppProviderFunc ({ reducer, apiClient, initialState, children }) {
  const [state, dispatch] = useReducer(reducer, initialState)

  const apiMiddleware = next => action => {
    apiClient(action, apiDispatch)
    return next(action)
  }

  const apiDispatch = useCallback(apiMiddleware(dispatch), [])

  return (
    <AppContext.Provider value={[state, apiDispatch]}>
      {children}
    </AppContext.Provider>
  )
}

AppProviderFunc.propTypes = {
  reducer: PropTypes.func,
  apiClient: PropTypes.func,
  initialState: PropTypes.object,
  children: PropTypes.object
}

export const AppProvider = AppProviderFunc
