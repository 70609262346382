import React, { useEffect, useState } from 'react'
import { API } from 'core/lib'
import PropTypes from 'prop-types'
import { Avatar, Chip, CircularProgress, Tooltip } from 'thirdparty'
import { ChipLabel } from './ChipLabel'

function ChipAPIField ({ option, onClickear, alEliminar, clave, getTagProps, maxWidth, ...props }) {
  const [value, setValue] = useState(option.value)

  useEffect(() => {
    setValue(option.value)
    if ((option.value.value === '' || !option.value.value) && option.value.key !== '') {
      API(option.tablaAPI)
        .get()
        .select(option.selectAPI)
        .filter([option.nombreCampo, 'eq', option.value.key])
        .success(response => {
          console.log('Recibido ', response.data)
          setValue(response.data.map(registro => { return { key: registro[option.nombreCampo], value: registro[option.buscarPor] } })[0])
        })
        .error(error => console.log('Error', error))
        .go()
    }
  }, [option.key, option.value])

  return (
    <Chip variant='outlined' {...getTagProps({ clave })}
      label={<ChipLabel maxWidth={maxWidth} >{
        value
          ? <Tooltip title={`${option.labelChip} ${value?.value}`}>
            <span>{`${option.labelChip} ${value.value ? value.value?.substring(0, 15) : ''}`}</span>
          </Tooltip>
          : 'Error'
      }</ChipLabel>}
      onClick={ option.clickDisabled ? null : (event) => onClickear(event, option)}
      onDelete={!value?.value ? (e) => true : value?.value && props.disabled ? null : value?.value && option.deleteDisabled ? null : !props.disabled && ((event) => alEliminar(event, option))}
      deleteIcon={value?.value ? null : <Avatar><CircularProgress key={`cargando${clave}`} style={{ maxHeight: '100%', maxWidth: '100%', width: '', height: '' }} color='primary'/></Avatar>}
      {...props}
      disabled={option.disabled}/>
  )
}

ChipAPIField.propTypes = {
  option: PropTypes.object,
  onClickear: PropTypes.any,
  alEliminar: PropTypes.any,
  clave: PropTypes.number,
  getTagProps: PropTypes.func,
  disabled: PropTypes.bool,
  maxWidth: PropTypes.number
}

ChipAPIField.defaultProps = {
}

export default ChipAPIField
