import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import TabPanel from 'quimera-comps/Tabs/TabPanel'
import { useStateValue, util } from 'quimera'
import { AppBar } from 'quimera-comps'
import { Tab as TabMUI, Tabs as TabsMUI } from 'thirdparty'

function TabWidget({ id, field, value, children, appBarProps, tabProps, tabPanelProps, button, ...props }) {
  const panels = React.Children.toArray(children).filter(child => child.type === 'Tab')

  const [state, dispatch] = useStateValue()
  const stateField = field || id
  const [stateValue, setStateValue] = useState(value || util.getStateValue(stateField, state, value) || 0)

  useEffect(() => {
    stateField && setStateValue(util.getStateValue(stateField, state, value))
  }, [state])
  // const stateValue = value || util.getStateValue(stateField, state, value) // vinculamos el id a que sea variable de nuestro padre

  const handleChange = (...[, idx]) => {
    if (stateField) {
      dispatch({
        type: `on${util.camelId(id)}Changed`,
        payload: { field: stateField, value: idx }
      })
    } else {
      setStateValue(idx)
    }
  }

  return (
    <React.Fragment>
      <AppBar position='static' {...appBarProps}>
        <TabsMUI variant="fullWidth" value={stateValue} onChange={handleChange} aria-label='tab widget' {...props}>
          {
            panels.map((panel, idx) =>
              <TabMUI key={`TabTitle${util.camelId(panel.props.title)}-${idx}`} label={panel.props.title} id={`tab-${idx}`} aria-controls={`tabpanel-${idx}`} {...tabProps} />
            )
          }
          {button}
        </TabsMUI>
      </AppBar>
      {
        panels.map((panel, idx) =>
          <TabPanel
            key={`TabPanel${util.camelId(panel.props.title)}-${idx}`}
            index={idx}
            value={stateValue}
            {...tabPanelProps}>
            {panel.props.children}
          </TabPanel>
        )
      }
    </React.Fragment>
  )
}

TabWidget.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  field: PropTypes.string,
  /** Children */
  children: PropTypes.any,
  /** appBar props */
  appBarProps: PropTypes.object,
  /** tabMUI props */
  tabProps: PropTypes.object,
  /** tabPanel props */
  tabPanelProps: PropTypes.object,
  button: PropTypes.any,
}

TabWidget.defaultProps = {}

export default TabWidget
