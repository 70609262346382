import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useStateValue, util } from 'quimera'
import { NumberFormat, TextField } from 'quimera-comps'
import { makeStyles } from 'quimera-styles'

const useStyles = makeStyles(theme => ({
  root: null,
  overrides: theme.overrides // theme.textField
}))

function FloatField ({
  id,
  decimals,
  textAlign,
  field,
  value,
  updateTime,
  formatoCodigo,
  ...props
}) {
  const thousandSeparator = formatoCodigo ? '' : '.'
  const decimalSeparator = ','
  const classes = useStyles()
  const [state, dispatch] = useStateValue()
  const [timer, setTimer] = useState()

  const stateField = field || id
  const stateValue = (value || value === '') ? value : util.getStateValue(stateField, state, value)

  const handleValueChange = (e) => {
    if (!stateField || stateField === '') {
      return
    }
    if (e.floatValue === stateValue) {
      return
    }
    dispatch({
      type: `on${util.camelId(id)}Changed`,
      payload: { 
        field: util.lastStateField(stateField),
        value: !formatoCodigo 
          ? e.floatValue 
          : e.formattedValue.length > 6 
            ? stateValue
            : e.floatValue
        }
    })

    if (updateTime) {
      const newval = e.floatValue
      clearTimeout(timer)
      setTimer(setTimeout(() => dispatch({
        type: `on${util.camelId(id)}Timeout`,
        payload: { field: util.lastStateField(stateField), value: newval }
      }), updateTime))
    }
  }

  const handleFocus = (e) => {}
  const handleBlur = (e) => {
    if (!stateField || stateField === '') {
      return
    }
    console.log('Value ', stateValue)
    dispatch({
      type: `on${util.camelId(id)}Blurred`,
      payload: { field: util.lastStateField(stateField), value: stateValue }
    })
  }

  return (
    <NumberFormat
      id={id || stateField}
      value={stateValue}
      onValueChange={handleValueChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      thousandSeparator={thousandSeparator}
      decimalSeparator={decimalSeparator}
      fixedDecimalScale={true}
      decimalScale={decimals}
      margin='dense'
      inputProps={{ style: { textAlign: textAlign || 'end' } } }
      customInput={TextField}
      className={classes.root}
      {...props}
    />
  )
}

FloatField.propTypes = {
  /** Number of decimals to render */
  decimals: PropTypes.number,
  /** Id for reference */
  id: PropTypes.string,
  /** Field for state update */
  field: PropTypes.string,
  /** Value of the field */
  value: PropTypes.any,
  /** Field with no label nor lines */
  updateTime: PropTypes.number
}

FloatField.defaultProps = {
  decimals: 2
}

export default FloatField
