import React from 'react'
import PropTypes from 'prop-types'
import { env } from 'environment'
import { util, useStateValue } from 'quimera'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Box } from 'thirdparty'
// import './Table.css'

import { makeStyles } from 'quimera-styles'

const useStyles = makeStyles(theme => ({
  header: {
    borderBottom: '2px solid darkgrey',
    // paddingTop: theme.spacing(1),
    // paddingBottom: theme.spacing(1)
  },
  headerSinColor: {
    borderBottom: '2px solid darkgrey',
    backgroundColor: 'inherit'
  },
  cursorPointer: {
    cursor: 'pointer'
  }
}))

function Table ({ id, data, idField, orderColumn, children, next, hasMore, headerSinColor, clickMode, bgcolorRowFunction, scrollableTarget, ...props }) {
  const classes = useStyles()
  const visibleLoading = env.inDevelopment()

  return (
    <Box id='scrollableDiv' name={id} {...props} >
      <Box key='header' alignItems='center' display='flex' className={ headerSinColor ? classes.headerSinColor : classes.header} position='-webkit-sticky' top={0} bgcolor='background.paper'>
        { children.map(column =>
          column.props && React.cloneElement(column, { key: column.props.id, isHeader: true, tableName: id, orderColumn: orderColumn })
        )}
      </Box>
      <InfiniteScroll
        dataLength={data.length}
        next={next}
        hasMore={hasMore}
        loader={visibleLoading && <h4>Loading...</h4>}
        endMessage={
          <p style={{ textAlign: 'center' }}>
            <b></b>
          </p>
        }
        scrollableTarget={scrollableTarget}
        // Quitar scrollableTarget='scrollableDiv' para que depende del scroll principal de la pantalla
      >
        <Box id={`${id}Body`} >
          { data.map(rowdata =>
            <Box alignItems='center' display='flex' alignContent='baseline' key={rowdata[idField]} minHeight={40}
              bgcolor={bgcolorRowFunction ? bgcolorRowFunction(rowdata) : null}
              // onClick={() => dispatch({ type: `on${util.camelId(id)}RowClicked`, payload: rowdata })}
              className={bgcolorRowFunction ? classes.cursorPointer : null}>
              { children.map(column => {
                return column.props && React.cloneElement(column, { key: `${rowdata[idField]}${util.camelId(column.props.id)}`, tableName: id, data: rowdata, clickMode: clickMode })
              })}
            </Box>
          )}
        </Box>
      </InfiniteScroll>
    </Box>
  )
}

Table.propTypes = {
  /** Indica si el clic en una celda llama a una función global de la fila o a una función particular de la columna. Los posibles valores son:
   * line: llama a on + (nombreTabla) + RowClicked
   * (otro valor): llama a on + (nombreTabla) + (nombreColumna) + Clicked
  */
  clickMode: PropTypes.string,
  /** Internal id for component instance reference */
  id: PropTypes.string.isRequired,
  /** Data table rows */
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** Field id of the ID column */
  idField: PropTypes.string,
  /** TableColumn instances for data row visualization */
  children: PropTypes.arrayOf(PropTypes.element),
  /** Dict with current ordered column ('field' key) and ordering direction ('direction' key) */
  orderColumn: PropTypes.object,
  /** Next func for pagination */
  next: PropTypes.func,
  /** Wheter if it has more records to show */
  hasMore: PropTypes.bool,
  /**  */
  /** Change the row bgcolor with a function */
  bgcolorRowFunction: PropTypes.func
}

Table.defaultProps = {
  idField: 'id',
  children: [],
  hasMore: false,
  clickMode: 'cell'
}

export default Table
