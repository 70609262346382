import * as locales from 'date-fns/locale'
import { formatRelative, isFuture, subDays } from 'date-fns'

let user = JSON.parse(localStorage.getItem('user'))
const localSettings = {}
const signosToQuimeraAPI = { '<=': 'lte', '=': 'eq', '>=': 'gte' }

function formatter(value, decimalNumber = 2) {
  if (!value) {
    return value
  }
  const locale = getLocale()
  // Con es-Es no aparecen los . de los miles
  // const LANGUAGE_CODE = navigator.language || navigator.userLanguage

  // const nformat = new Intl.NumberFormat('de-DE', { style: 'decimal', maximumFractionDigits: decimalNumber, useGrouping: true })
  const nformat = new Intl.NumberFormat(locale, { style: 'decimal', maximumFractionDigits: decimalNumber, useGrouping: true })
  value = nformat.format(value)

  if (!value) {
    return false
  }

  return value
}

function euros(value) {
  if (!value && value !== 0) {
    return value
  }
  const locale = getLocale() === 'es-ES' ? 'de-DE' : getLocale()
  // Con es-Es no aparecen los . de los miles
  // const LANGUAGE_CODE = navigator.language || navigator.userLanguage

  // const nformat = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', useGrouping: true })
  const nformat = new Intl.NumberFormat(locale, { style: 'currency', currency: 'EUR', useGrouping: true })

  value = nformat.format(value)

  if (!value) {
    return false
  }

  return value
}

function formatDate(value) {
  if (value == null) {
    return ''
  }
  const timeZone = getZonaHoraria()
  const options = { month: '2-digit', day: '2-digit', year: 'numeric', timeZone: timeZone }
  const fecha = new Date(Date.parse(value))
  const locale = getLocale()
  // alert(locale)
  return fecha.toLocaleDateString(/* 'es-ES' */locale, options)
}

function getLocale() {
  return user ? user.locale || navigator.language : navigator.language// es-ES
}

function setSetting(clave, valor) {
  localSettings[clave] = valor
}

function getSetting(clave) {
  return localSettings[clave]
}

// las funciones Global son las que guardan en el navegador y las que no llevan Global las que guardan en memoria

function getGlobalSetting(clave) {
  return JSON.parse(localStorage.getItem(clave))
}

function setGlobalSetting(clave, valor) {
  localStorage.setItem(clave, JSON.stringify(valor))
}

function newDate() {
  return new Date()
}

function nombreDia(date) {
  const weekDay = new Date(date).getDay()
  const nombres = ['domingo', 'lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado']
  return nombres[weekDay]
}

function numerosSemanas(date) {
  var primerDia = new Date(date)
  setFirstMonthDay(primerDia)
  var dia = primerDia.getDay() || 6
  dia = dia === 1 ? 0 : dia
  if (dia) { dia-- }
  var diff = 7 - dia
  var ultimoDia = new Date(date)
  setLastMonthDay(ultimoDia)
  var ultimaFecha = ultimoDia.getDate()
  if (ultimoDia.getDay() === 1) {
    diff--
  }
  var result = Math.ceil((ultimaFecha - diff) / 7)
  return result + 1
}

function addDays(date, days) {
  return date.setDate(date.getDate() + days)
}

function addMonths(date, months) {
  return date.setMonth(date.getMonth() + months)
}

function addYears(date, years) {
  return date.setFullYear(date.getFullYear() + years)
}

function setFirstMonthDay(date) {
  return date.setDate(1)
}

function getLastMonthDay(date) {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
}

function setPreviousDay(date) {
  return addDays(date, -1)
}

function setNextDay(date) {
  return addDays(date, 1)
}

function setLastMonthDay(date) {
  date.setMonth(date.getMonth() + 1)
  return date.setDate(0)
}

function setFirstWeekDay(date) {
  const weekDay = date.getDay() || 7
  return addDays(date, (weekDay - 1) * -1)
}

function setLastWeekDay(date) {
  const weekDay = date.getDay() || 7
  return addDays(date, (7 - weekDay))
}

function setFirstMonth(date) {
  return date.setMonth(0)
}

function setPreviousMonth(date) {
  return addMonths(date, -1)
}

function setNextMonth(date) {
  return addMonths(date, 1)
}

function setLastMonth(date) {
  return date.setMonth(11)
}

function setPreviousYear(date) {
  return addYears(date, -1)
}

function setNextYear(date) {
  return addYears(date, 1)
}

function dateToIsoString(date) {
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`
}

function getZonaHoraria() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

const capitalize = s => typeof s === 'string' ? `${s.charAt(0).toUpperCase()}${s.slice(1)}` : s

let sessionUser = null

const funciones = {
  today: function () {
    return dateToIsoString(newDate())
  },
  yesterday: function () {
    const date = newDate()
    setPreviousDay(date)
    return dateToIsoString(date)
  },
  tomorrow: function () {
    const date = newDate()
    setNextDay(date)
    return dateToIsoString(date)
  },
  firstOfWeek: function () {
    const date = newDate()
    setFirstWeekDay(date)
    return dateToIsoString(date)
  },
  lastOfWeek: function () {
    const date = newDate()
    setLastWeekDay(date)
    return dateToIsoString(date)
  },
  firstOfPreviousWeek: function () {
    const date = newDate()
    addDays(date, -7)
    setFirstWeekDay(date)
    return dateToIsoString(date)
  },
  lastOfPreviousWeek: function () {
    const date = newDate()
    addDays(date, -7)
    setLastWeekDay(date)
    return dateToIsoString(date)
  },
  firstOfNextWeek: function () {
    const date = newDate()
    addDays(date, 7)
    setFirstWeekDay(date)
    return dateToIsoString(date)
  },
  lastOfNextWeek: function () {
    const date = newDate()
    addDays(date, 7)
    setLastWeekDay(date)
    return dateToIsoString(date)
  },
  firstOfMonth: function () {
    const date = newDate()
    setFirstMonthDay(date)
    return dateToIsoString(date)
  },
  lastOfMonth: function () {
    const date = newDate()
    setLastMonthDay(date)
    return dateToIsoString(date)
  },
  firstOfPreviousMonth: function () {
    const date = newDate()
    setPreviousMonth(date)
    setFirstMonthDay(date)
    return dateToIsoString(date)
  },
  lastOfPreviousMonth: function () {
    const date = newDate()
    setPreviousMonth(date)
    setLastMonthDay(date)
    return dateToIsoString(date)
  },
  firstOfNextMonth: function () {
    const date = newDate()
    setNextMonth(date)
    setFirstMonthDay(date)
    return dateToIsoString(date)
  },
  lastOfNextMonth: function () {
    const date = newDate()
    setNextMonth(date)
    setLastMonthDay(date)
    return dateToIsoString(date)
  },
  firstOfYear: function () {
    const date = newDate()
    setFirstMonth(date)
    setFirstMonthDay(date)
    return dateToIsoString(date)
  },
  lastOfYear: function () {
    const date = newDate()
    setLastMonth(date)
    setLastMonthDay(date)
    return dateToIsoString(date)
  },
  firstOfPreviousYear: function () {
    const date = newDate()
    setPreviousYear(date)
    setFirstMonth(date)
    setFirstMonthDay(date)
    return dateToIsoString(date)
  },
  lastOfPreviousYear: function () {
    const date = newDate()
    setPreviousYear(date)
    setLastMonth(date)
    setLastMonthDay(date)
    return dateToIsoString(date)
  },
  firstOfNextYear: function () {
    const date = newDate()
    setNextYear(date)
    setFirstMonth(date)
    setFirstMonthDay(date)
    return dateToIsoString(date)
  },
  lastOfNextYear: function () {
    const date = newDate()
    setNextYear(date)
    setLastMonth(date)
    setLastMonthDay(date)
    return dateToIsoString(date)
  },
  getOptionByKey: function (options, itemKeyValue, optionKey, itemKey) {
    optionKey = optionKey || 'key'
    itemKey = itemKey || optionKey
    const item = {}
    item[itemKey] = itemKeyValue
    return this.getOption(options, item, optionKey, itemKey)
  },
  getOption: function (options, item, optionKey, itemKey) {
    if (this.isEmptyObject(item)) {
      return {}
    }
    if (options.length === 0) {
      return item
    }
    optionKey = optionKey || 'key'
    itemKey = itemKey || optionKey
    return this.findOption(options, optionKey, item[itemKey])
  },
  findOption: function (options, keyName, keyValue) {
    if (keyValue === undefined) {
      return {}
    }
    const incluidos = options.filter(o => o[keyName] === keyValue)
    if (incluidos.length > 0) {
      return incluidos[0]
    } else {
      return {}
    }
  },
  getOptions: function (options, items, optionKey, itemKey) {
    if (items.length === 0) {
      return []
    }
    if (options.length === 0) {
      return items
    }
    optionKey = optionKey || 'key'
    itemKey = itemKey || optionKey
    const seleccionados = items.reduce((acum, item) => {
      const seleccionado = options.filter(option => option[optionKey] === item[itemKey])
      return [...acum, ...seleccionado]
    }, [])
    return seleccionados
  },
  agregarRecurrenciaPersonalizada(rangosMensualPersonal, recurrencia, limitesRecurrencia, fechaInicioOriginal) {
    const { cada, fechainicio, fechafin, repeticionPersonal, recurrenciaMensualPersonal } = recurrencia
    const tipo = recurrencia.rangoRecurrenciaPersonal
    const limite = limitesRecurrencia.filter(k => k.key == tipo.substr(1, 2))[0].limite
    const fechafinDefecto = this.dateToIsoString(this.addYears(fechainicio, limite))
    const { nombreDelDia, codigoDia, nombreMes, codigoMes, diaEnElMes, codigoPosicionMes, dia } = this.datosRecurrencia(fechafin)
    const miDia = repeticionPersonal.join("")
    let recurrenciaAñadida
    if (tipo == 'PCS') {
      const dias = this.nombreDias(repeticionPersonal)
      const CS = repeticionPersonal.length === 1
      const tipoSem = CS ? 'CS' : 'PCS'
      let mikey = fechafinDefecto != fechafin ? 'PCS' : 'CS'
      let valor = `Cada semana${CS ? ' el ' : ': ('}${dias}${!CS ? ')' : ''}`
      cada === 1
        ? fechafinDefecto != fechafin || fechainicio != fechaInicioOriginal
          ? recurrenciaAñadida = { key: tipoSem, tipo: tipoSem, value: valor, dia: miDia }
          : tipoSem == 'PCS'
            ? recurrenciaAñadida = { key: tipoSem, tipo: tipoSem, value: valor, dia: miDia }
            : recurrenciaAñadida = null
        : recurrenciaAñadida = { key: 'PCS', tipo: 'PCS', value: `Cada ${cada} semanas: (${dias})`, dia: miDia }
    }
    else if (tipo == 'PCD') {
      cada === 1
        ? fechafinDefecto != fechafin || fechainicio != fechaInicioOriginal
          ? recurrenciaAñadida = { key: 'PCD', tipo: 'CD', value: `Todos los días`, dia: miDia }
          : recurrenciaAñadida = null
        : recurrenciaAñadida = { key: 'PCD', tipo: 'CD', value: `Cada ${cada} días`, dia: miDia }
    }
    else if (tipo?.substr(0, 3) == 'PCM') {
      const miObjeto = rangosMensualPersonal.filter(r => r.key == recurrenciaMensualPersonal)[0]
      cada === 1
        ? fechafinDefecto != fechafin || fechainicio != fechaInicioOriginal
          ? recurrenciaAñadida = miObjeto
          : miObjeto.key === 'PCMD'
            ? recurrenciaAñadida = miObjeto
            : recurrenciaAñadida = null
        : recurrenciaAñadida = { key: miObjeto.key, tipo: miObjeto.tipo, value: miObjeto.value.replace("Cada mes", `Cada ${cada} meses el día `), dia: miObjeto.dia }
    }
    else if (tipo == 'PCA') {
      cada === 1
        ? fechafinDefecto != fechafin
          ? recurrenciaAñadida = { key: 'PCA', tipo: 'CA', value: `Cada año el ${dia} de ${nombreMes}`, dia: dia + '/' + codigoMes }
          : fechainicio != fechaInicioOriginal
            ? recurrenciaAñadida = { key: 'CA', tipo: 'CA', value: `Cada año el ${dia} de ${nombreMes}`, dia: dia + '/' + codigoMes }
            : recurrenciaAñadida = null
        : recurrenciaAñadida = { key: 'PCAP', tipo: 'PCAP', value: `Cada ${cada} años el ${dia} de ${nombreMes}`, dia: dia + '/' + codigoMes }
    }
    if (recurrenciaAñadida) {
      recurrenciaAñadida.fechaInicio = fechainicio
      recurrenciaAñadida.fechaFin = fechafin
      recurrenciaAñadida.cada = cada
      if (fechainicio != fechaInicioOriginal) {
        recurrenciaAñadida.value += ` desde (${this.formatDate(fechainicio)})`
      }
      if (fechafinDefecto != fechafin) {
        recurrenciaAñadida.value += ` hasta (${this.formatDate(fechafin)})`
      }
    }
    return recurrenciaAñadida
  },
  datosRecurrencia(date) {
    const nombreDelDia = this.infoDia(date).nombre
    const codigoDia = this.infoDia(date).codigo
    const nombreMes = this.infoMes(date).nombre
    const codigoMes = this.infoMes(date).codigo
    const diaEnElMes = this.diaEnElMes(date).nombre
    const codigoPosicionMes = this.diaEnElMes(date).codigo
    const dia = date.substr(8, 1) === '0' ? date.substr(9, 1) : date.substr(8, 2)
    return { nombreDelDia, codigoDia, nombreMes, codigoMes, diaEnElMes, codigoPosicionMes, dia }
  },
  infoDia(date) {
    const weekDay = new Date(date).getDay()
    const nombres = ['domingo', 'lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado']
    const codigos = ['D', 'L', 'M', 'X', 'J', 'V', 'S']
    return { nombre: nombres[weekDay], codigo: codigos[weekDay] }
  },
  addYears(date, years) {
    const fecha = new Date(addYears(new Date(date), years))
    return fecha
  },
  miFechaOHoy(date) {
    const fecha = typeof date == 'string' ? new Date(date) : date
    return isFuture(fecha) ? fecha : new Date()
  },
  infoMes(date) {
    const month = new Date(date).getMonth()
    const nombres = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre']
    return { nombre: nombres[month], codigo: month + 1 }
  },
  diaEnElMes(date) {
    const miFecha = new Date(date)
    const miNumDia = parseInt(date.substr(8, 2))
    const miNombreDia = nombreDia(date)
    var posicion = -1
    var total = -1
    var ultimoDiaMes = getLastMonthDay(miFecha)
    setFirstMonthDay(miFecha)
    for (let i = 1; i <= ultimoDiaMes; i++) {
      nombreDia(miFecha) === miNombreDia && i <= miNumDia && posicion++
      nombreDia(miFecha) === miNombreDia && total++
      setNextDay(miFecha)
    }
    const nombres = ['primer', 'segundo', 'tercer', `${total === posicion ? 'último' : 'cuarto'}`, `${total === posicion ? 'último' : 'quinto'}`, 'último']
    return { nombre: nombres[posicion], codigo: nombres[posicion] === 'último' ? 'U' : posicion + 1 }
  },
  nombreDias(dias) {
    const letras = ['L', 'M', 'X', 'J', 'V', 'S', 'D']
    const nombres = ['lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado', 'domingo']
    const misdias = []
    for (let i = 0; i < dias.length; i++) {
      misdias.push(nombres[letras.indexOf(dias[i])])
    }
    return misdias.join(', ')
  },
  ordenarDias(array) {
    const semana = ['L', 'M', 'X', 'J', 'V', 'S', 'D']
    const ordenado = array.sort((a, b) => {
      return semana.indexOf(a) - semana.indexOf(b);
    })
    return ordenado
  },
  contarMisDiasEnElMes(anio, mes, misDias) {
    const indiceMes = parseInt(mes) - 1;
    const inicialDia = ['D', 'L', 'M', 'X', 'J', 'V', 'S'];
    const date = new Date(parseInt(anio), indiceMes, 1);
    let misDiasEnElMes = 0;
    while (date.getMonth() === indiceMes) {
      if (misDias.includes(inicialDia[date.getDay()])) {
        misDiasEnElMes++
      }
      date.setDate(date.getDate() + 1);
    }
    return misDiasEnElMes
  },
  relativeTime: (ISOStrDate) => formatRelative(new Date(ISOStrDate), new Date(), { locale: locale, weekStartsOn: 1 }),
  getAttr: (obj, attr, def) => attr in obj ? obj[attr] || def : def,
  // setUser: u => { user = localStorage.setItem('user', u) },
  // getUser: () => JSON.parse(localStorage.getItem('user')),
  setUser: u => { sessionUser = u },
  getUser: () => sessionUser,
  getUserToken: () => {
    const user = JSON.parse(localStorage.getItem('user'))
    const token = user && user.token ? `Token ${user.token}` : false
    return token
  },
  setUserToken: t => { localStorage.setItem('user', `{"token":"${t}"}`) },
  capitalize: s => capitalize(s),
  formatter: (v, n) => formatter(v, n),
  euros: (v) => euros(v),
  formatDate: (v) => formatDate(v),
  // camelId: id => id ? id.toString().slice(0, id.indexOf('/')).split('.').map(s => capitalize(s)).join('') : '',
  camelId: id => id ? id.toString().replace(/\/\w+/g, '').split('.').map(s => capitalize(s)).join('') : '',
  // getStateValue: (field, state, def) => field ? field.split('.').reduce((val, prop) => val[prop], state) : def,
  getStateValue: (field, state, def) => field ? field.replace('/', '.').split('.').reduce((val, prop) => val ? val[prop] : undefined, state) : def,
  lastStateField: field => field.indexOf('/') !== -1 ? field.replace('/', '.').split('.').pop() : field,
  // getStateValue: (field, state, def) => field ? field.split('.').reduce((val, prop) => prop.split('/').length === 2 ? val[prop[0]][prop[1]] : val[prop], state) : def,
  // getStateValue: (field, state, def) => {
  //   if (!field) {
  //     return def
  //   }
  //   return field.split('.').reduce((val, prop) => prop.split('/').length === 2 ? val[prop[0]][prop[1]] : val[prop], state) : def,
  fromKeyValueToObject: (key, value) => {
    const o = {}
    o[key] = value
    return o
  },
  isEmptyObject: (obj) => Object.keys(obj).length === 0 && obj.constructor === Object,
  // // /////
  getLocale: () => getLocale(),
  dateToIsoString: date => dateToIsoString(date),
  setSetting: (clave, valor) => setSetting(clave, valor),
  getSetting: (clave) => getSetting(clave),
  appDispatch: (props) => getSetting('appDispatch')(props),
  setPageTitle: title => (document.title = title),
  contadorFiltros: function (valores, propiedades) {
    let contador = 0
    propiedades.forEach(opcion => {
      if (opcion.nombreCampo in valores) {
        contador++
      }
    })
    return contador
  },
  ahoraEnHorasMinSeg: function () {
    return (new Date()).toLocaleTimeString('en-GB') // → "03:00:00" El inglés británico usa formato de 24 horas sin AM/PM
  },
  mesYAnyoANombre: function (mes, anyo) { // mes numero 2 digitos, año numero 4 digitos
    const fecha = new Date(`2019/${mes}/01`)
    const locale = getLocale()
    return `${this.camelId(fecha.toLocaleString(/* 'default' */locale, { month: 'long' }))} ${anyo}` // → default traduce el nombre de mes al idioma del navegador
  },
  // Funcion que convierte los minutos que le pasamos en horas y minutos ej: 200 => '03:20'
  minutosToHorasMins: function (mins) {
    let horas = Math.floor((mins / 60))
    let minutos = Math.round((mins / 60 - horas) * 60)
    if (minutos === 60) {
      minutos = 0
      horas++
    }
    horas = horas < 10 ? `0${horas}` : horas
    minutos = minutos < 10 ? `0${minutos}` : minutos
    console.log('minutosToHorasMins: ', mins, `${horas}:${minutos}`)
    return `${horas}:${minutos}`
  },
  // Funcion que convierte un string time a minutos ej: '01:00:00' => 60
  horasMinsAMinutos: function (horasMinsString) {
    if (horasMinsString === null || horasMinsString === undefined) {
      return 0
    }
    let minutos = horasMinsString.split(':')
    minutos = parseInt((+minutos[0]) * 60 + (+minutos[1]))
    // console.log('Minutos: ', minutos)
    return minutos
  },
  // Funcion que convierte los segundos que le pasamos en horas y minutos segundos ej: 120 => '00:02:00'
  segundosToHorasMinsSegs: function (segs) {
    let horas = Math.floor((segs / 3600))
    let minutos = Math.floor((segs / 60) % 60)
    let segundos = segs % 60
    horas = horas < 10 ? `0${horas}` : horas
    minutos = minutos < 10 ? `0${minutos}` : minutos
    segundos = segundos < 10 ? `0${segundos}` : segundos
    // console.log('segundosToHorasMinsSegs: ', segs, `${horas}:${minutos}:${segundos}`)
    return `${horas}:${minutos}:${segundos}`
  },
  // Funcion que convierte los segundos que le pasamos en horas y minutos segundos ej: 120 => '00:02:00'
  segundosToHorasMins: function (segs) {
    let horas = Math.floor((segs / 3600))
    let minutos = Math.floor((segs / 60) % 60)
    // let segundos = segs % 60
    horas = horas < 10 ? `0${horas}` : horas
    minutos = minutos < 10 ? `0${minutos}` : minutos
    // segundos = segundos < 10 ? `0${segundos}` : segundos
    // console.log('segundosToHorasMinsSegs: ', segs, `${horas}:${minutos}:${segundos}`)
    return `${horas}:${minutos}`
  },
  // Funcion que convierte un string time a segundos ej: '01:00:00' => 600
  horasMinsSegsToHorasMins: function (horasMinsSegsString) {
    // console.log('horasMinsSegsASegundos', horasMinsSegsString)
    if (horasMinsSegsString === null || horasMinsSegsString === undefined) {
      return 0
    }
    let segundos = horasMinsSegsString.split(':')
    segundos = parseInt((+segundos[0]) * 3600 + (+segundos[1]) * 60 + (+segundos[2]))
    let horasMins = this.segundosToHorasMins(segundos)
    // console.log('Minutos: ', minutos)
    return horasMins
  },
  // Funcion que convierte un string time a segundos ej: '01:00:00' => 600
  horasMinsSegsASegundos: function (horasMinsSegsString) {
    // console.log('horasMinsSegsASegundos', horasMinsSegsString)
    if (horasMinsSegsString === null || horasMinsSegsString === undefined) {
      return 0
    }
    let segundos = horasMinsSegsString.split(':')
    segundos = parseInt((+segundos[0]) * 3600 + (+segundos[1]) * 60 + (+segundos[2]))
    // console.log('Minutos: ', minutos)
    return segundos
  },
  // Funcion que convierte en porcentaje un dividendo y divisor
  formateoPorcentaje: function (dividendo, divisor) {
    let porcentajeFormateado = 0
    if (divisor !== 0) {
      porcentajeFormateado = (dividendo / divisor)
    }
    // porcentajeFormateado = porcentajeFormateado.toFixed(2).toString().replace('.', ',') + '%'
    const locale = getLocale() === 'es-ES' ? 'de-DE' : getLocale()
    const nformat = new Intl.NumberFormat(locale, { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 })
    porcentajeFormateado = nformat.format(porcentajeFormateado)
    return porcentajeFormateado
  },
  // Funcion que convierte un string time a minutos ej: '01:00:00' => 3600
  // horasMinsSegsASegundos: function (horasMinsSegsString) {
  //   let segundos = horasMinsSegsString.split(':')
  //   segundos = parseInt(((+segundos[0]) * 3600) + ((+segundos[1]) * 60) + (+segundos[2]))
  //   // console.log('segundos: ', segundos)
  //   return segundos
  // },
  getFormatWordsDate: function () {
    const formatWords = getLocale() === 'en-US' ? 'MM/dd/yyyy' : 'dd/MM/yyyy'
    return formatWords
  },
  getZonaHoraria: () => getZonaHoraria(),
  getLocaleDateFNS: function () {
    if (!getSetting('objetoLocale')) {
      setSetting('objetoLocale', locales[getLocale().substr(0, 2)] || locales.enGB)
    }
    return getSetting('objetoLocale')
  },
  getUniqueOptions: options => {
    if (options.length === 0) { return [] }
    const optionsNoRepetidos = []
    for (let i = 0; i < options.length; i++) {
      if (options[i] && !optionsNoRepetidos.find(op => op.key === options[i].key)) {
        optionsNoRepetidos.push(options[i])
      }
    }
    return optionsNoRepetidos
  },
  setGlobalSetting: (clave, valor) => setGlobalSetting(clave, valor),
  getGlobalSetting: (clave) => getGlobalSetting(clave),

  getLastFilter: filterName => {
    const filterNameU = `${filterName}-${getGlobalSetting('user').user}`
    let filter = getSetting(filterNameU)
    if (!filter) {
      filter = getGlobalSetting(filterNameU)
    }
    return filter
  },
  setLastFilter: (filterName, filter) => {
    const filterNameU = `${filterName}-${getGlobalSetting('user').user}`
    setSetting(filterNameU, filter)
    setGlobalSetting(filterNameU, filter)
  },

  getUltimoConjunto: () => {
    const conjuntoU = `ultimoConjuntoEstados-${getGlobalSetting('user').token}`
    let conjunto = getSetting(conjuntoU)
    if (!conjunto) {
      conjunto = getGlobalSetting(conjuntoU)
    }
    return conjunto
  },
  setUltimoConjunto: (conjunto) => {
    const conjuntoU = `ultimoConjuntoEstados-${getGlobalSetting('user').token}`
    setSetting(conjuntoU, conjunto)
    setGlobalSetting(conjuntoU, conjunto)
  },

  getFiltroComunKanban: () => {
    const filtroU = `filtroComunKanban-${getGlobalSetting('user').token}`
    let filtro = getSetting(filtroU)
    if (!filtro) {
      filtro = getGlobalSetting(filtroU)
    }
    return filtro
  },
  setFiltroComunKanban: (value) => {
    const filtroU = `filtroComunKanban-${getGlobalSetting('user').token}`
    setSetting(filtroU, value)
    setGlobalSetting(filtroU, value)
  },

  getUltimaRutaAntesGestion: () => {
    const rutaU = `ultimaRutaAntesGestion-${getGlobalSetting('user').token}`
    let ruta = getSetting(rutaU)
    if (!ruta) {
      ruta = getGlobalSetting(rutaU)
    }
    return ruta
  },
  setUltimaRutaAntesGestion: (ruta) => {
    const rutaU = `ultimaRutaAntesGestion-${getGlobalSetting('user').token}`
    setSetting(rutaU, ruta)
    setGlobalSetting(rutaU, ruta)
  },

  buildFilter: (autocompleteValue, propiedades) => {
    console.log('autocompleteValue', autocompleteValue)
    const valores = Array.isArray(autocompleteValue)
      ? autocompleteValue.reduce((acum, actual) => ({ ...acum, [actual.nombreCampo]: actual.opciones || actual.value }), {})
      : autocompleteValue

    console.log('valores', valores)
    const clausulas = [] /* hola */
    propiedades.forEach(opcion => {
      if (opcion.nombreCampo in valores) {
        const nombreCampo = opcion.nombreCampo
        const valor = valores[nombreCampo]
        console.log('nombreCampo', nombreCampo, 'valor', valor)
        if (opcion.tipoCampo === 'apiselect') {
          clausulas.push([opcion.nombreCampo, 'eq', valor.value.key])
        }

        switch (opcion.tipoCampo) {
          case 'string':
            clausulas.push([nombreCampo, 'like', valor.value])
            break

          case 'checkboxmultiple':
            {
              console.log('valor', valor)
              const clausulaOr = []
              const valoresOpcion = Array.isArray(valor.opciones) ? valor.opciones : [valor]
              console.log('valoresOpcion', valoresOpcion)
              valoresOpcion.forEach(op => op.value && clausulaOr.push([nombreCampo, 'eq', op.key]))
              clausulaOr.length > 0 && clausulas.push({ or: clausulaOr })
              break
            }

          case 'boolean':
            if (nombreCampo === 'idtarearecurrente') {
              const nullable = valor.value ? 'is_not_null' : 'is_null'
              clausulas.push([nombreCampo, nullable])
            } else {
              clausulas.push([nombreCampo, 'eq', valor.value])
            }
            break

          case 'date':
            {
              let valorTraducir
              if (valor.value.persistencia) {
                !rangosEIntervalosFecha[valor.value.persistencia] && alert('campo persistencia no coincide, revisar prop propiedades')
                valorTraducir = rangosEIntervalosFecha[valor.value.persistencia]
              } else {
                valorTraducir = Object.assign({}, valor.value) // copiamos objeto
              }
              valorTraducir.fecha && clausulas.push([opcion.nombreCampo, 'eq', valorTraducir.fecha])
              valorTraducir.desde && clausulas.push([opcion.nombreCampo, 'gte', valorTraducir.desde])
              valorTraducir.hasta && clausulas.push([opcion.nombreCampo, 'lte', valorTraducir.hasta])
              if (!valorTraducir.fecha && !valorTraducir.desde && !valorTraducir.hasta) {
                clausulas.push([opcion.nombreCampo, 'is_null'])
              }
              break
            }

          case 'number':
            console.log('signosToQuimeraAPI', opcion)
            clausulas.push([nombreCampo, signosToQuimeraAPI[valor.signo] || 'eq', valor.value])
            break
        }
      }
    })
    const filtro = clausulas.length > 0 ? { and: clausulas } : {}
    return filtro
  },

  limpiarLocation: (location) => {
    let limpia = location.slice(1)
    if (limpia?.indexOf('/') != -1 && (limpia.includes('proyecto/') || limpia.includes('tarea/'))) {
      limpia = limpia.includes('hito/') ? undefined : limpia.slice(0, limpia.indexOf('/') + 1)
    }
    return limpia
  },
  rangosEIntervalosFecha: () => rangosEIntervalosFecha,
  deRangosARangosMostrar: () => deRangosARangosMostrar,
  intervalosTiempo: () => intervalosTiempo,
  metrics: {
    cabeceraDesktopHeight: 50,
  }
}

const rangosEIntervalosFecha = {
  hoy: { desde: null, hasta: null, fecha: funciones.today() },
  ayer: { desde: null, hasta: null, fecha: funciones.yesterday() },
  manana: { desde: null, hasta: null, fecha: funciones.tomorrow() },
  hastaayer: { desde: null, hasta: funciones.yesterday(), fecha: null },
  estasemana: { desde: funciones.firstOfWeek(), hasta: funciones.lastOfWeek(), fecha: null },
  estemes: { desde: funciones.firstOfMonth(), hasta: funciones.lastOfMonth(), fecha: null },
  esteanyio: { desde: funciones.firstOfYear(), hasta: funciones.lastOfYear(), fecha: null },
  ultimos7dias: { desde: funciones.dateToIsoString(subDays(new Date(), 7)), hasta: null, fecha: null },
  ultimos30dias: { desde: funciones.dateToIsoString(subDays(new Date(), 30)), hasta: null, fecha: null },
  haceMasDe30dias: { desde: null, hasta: funciones.dateToIsoString(subDays(new Date(), 30)), fecha: null },
  hastaHace7dias: { desde: null, hasta: funciones.dateToIsoString(subDays(new Date(), 8)), fecha: null }
}

// tiene que haber los mismos que en el objeto rangosEIntervalosFecha
const deRangosARangosMostrar = {
  hoy: 'Hoy',
  ayer: 'Ayer',
  manana: 'Mañana',
  hastaayer: 'Hasta ayer',
  estasemana: 'Esta Semana',
  estemes: 'Este mes',
  esteanyio: 'Este año',
  ultimos7dias: 'Últimos 7 días',
  ultimos30dias: 'Últimos 30 días',
  haceMasDe30dias: 'Hace más de 30 días',
  hastaHace7dias: 'Hasta hace 7 días'
}

const intervalosTiempo = [
  { nombre: '', fecha: null, desde: null, hasta: null },
  { nombre: 'Hoy', persistencia: 'hoy' },
  { nombre: 'Ayer', persistencia: 'ayer' },
  { nombre: 'Esta semana', persistencia: 'estasemana' },
  { nombre: 'Hasta ayer', persistencia: 'hastaayer' },
  { nombre: 'Este mes', persistencia: 'estemes' },
  { nombre: 'Este año', persistencia: 'esteanyio' }
]

const locale = funciones.getLocaleDateFNS()
export default funciones
