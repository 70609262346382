import React from 'react'
import PropTypes from 'prop-types'
import { BaseField } from 'quimera-comps'
import RadioGroup from 'quimera-comps/Field/RadioField/RadioGroup'
import RadioField from 'quimera-comps/Field/RadioField/RadioField'

function RadioBase ({ id, options, ...props }) {
  return options ? (
    <BaseField
      id={id}
      Component={
        <RadioGroup id={id} options={options}/>
      }
      {...props}
    />
  ) : (
    <BaseField
      id={id}
      className={''}
      Component={
        <RadioField id={id}/>
      }
      {...props}
    />
  )
}

RadioBase.propTypes = {
  /** Id for reference */
  id: PropTypes.string.isRequired,
  /** Different options of the field */
  options: PropTypes.array
}

RadioBase.defaultProps = {}

export default RadioBase
