import React from 'react'
import PropTypes from 'prop-types'
import { BaseField } from 'quimera-comps'
import { Autocomplete } from 'thirdparty'

function AutocompleteField ({ label, value, options, onInputChange, fullWidth, variant, InputProps, noOptionsText, ...props }) {
  return (
    <Autocomplete
      getOptionLabel={ option => (option.value || (option.key ? option.key.toString() : '')) }
      options={ options }
      loading={ false }
      value={ value }
      autoHighlight
      renderInput={params => (
        <BaseField
          {...params}
          id=''
          label={ label }
          onChange={ onInputChange }
          fullWidth={ fullWidth }
          variant={ variant }
          InputProps={{
            ...params.InputProps,
            ...InputProps
          }}
        />
      )}
      noOptionsText={ noOptionsText || '' }
      {...props}
    />
  )
}

AutocompleteField.propTypes = {
  /** Label for the field */
  label: PropTypes.string,
  /** Value of the field */
  value: PropTypes.any,
  /** Suggested options */
  options: PropTypes.array,
  /** Function to execute onInputChange */
  onInputChange: PropTypes.func,
  fullWidth: PropTypes.bool,
  variant: PropTypes.string,
  noOptionsText: PropTypes.string,
  InputProps: PropTypes.any
}

export default AutocompleteField
