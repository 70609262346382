import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useStateValue, util } from 'quimera'
import AutocompleteField from 'quimera-comps/Field/AutocompleteField/AutocompleteField'

function AutocompleteBase ({ async, id, field, timeout, minCharacters, onChange, value, ...props }) {
  const [state, dispatch] = useStateValue()
  const [timer, setTimer] = useState()
  const [options, setOptions] = useState([])

  const stateField = field || id
  const stateValue = value || util.getStateValue(stateField, state, null)

  const handleChange = useCallback((event, value) => {
    console.log('Autocomplete', id, 'dispatching', value)
    dispatch({
      type: `on${util.camelId(id)}Changed`,
      payload: { field: util.lastStateField(stateField), value: value || {} }
    })
  }, [id, stateField, dispatch])

  const handleInputChange = useCallback((event) => {
    const newval = event.target.value
    clearTimeout(timer)
    setTimer(setTimeout(() => handleUpdate(newval), timeout))
  }, [timer, setTimer, timeout])

  const handleUpdate = useCallback((v) => {
    if (v.length < minCharacters) {
      return
    }

    dispatch({
      type: `on${util.camelId(id)}Updated`,
      payload: { callback: setOptions, value: v, key: undefined }
    })
  }, [id, dispatch])

  // if (async && stateValue && typeof stateValue !== 'object')
  // const handleUpdate = useCallback((value) => {
  //   if (value.length < minCharacters) {
  //     return
  //   }

  //   dispatch({
  //     type: `on${util.camelId(id)}Updated`,
  //     payload: { callback: setLabel, value: value, key: undefined }
  //   })
  // }, [id, dispatch])

  const handleFocus = event => {
    return true
  }

  const handleBlur = event => {
    return true
  }

  return (
    <AutocompleteField
      id={ id }
      value= { stateValue }
      options={ props.options || options }
      onChange={ onChange || handleChange }
      onInputChange={ async ? handleInputChange : null }
      onFocus={ handleFocus }
      onBlur={ handleBlur }
      { ...props }/>
  )
}

AutocompleteBase.propTypes = {
  /** Id for reference */
  id: PropTypes.string.isRequired,
  /** Field for state update */
  field: PropTypes.string,
  /** Label for the field */
  label: PropTypes.string,
  /** Milliseconds before trigger */
  timeout: PropTypes.number,
  /** Minimun number of characters before trigger */
  minCharacters: PropTypes.number,
  /** Whether the control takes its properties from props (false) or an async function (true) */
  async: PropTypes.bool,
  /** Functión onChange. Si no se especifica se lanza un dispatch onIdChanged con el array de opciones seleccionado */
  onChange: PropTypes.func,
  /** Options list for sync controls */
  options: PropTypes.array
}

AutocompleteBase.defaultProps = {
  timeout: 500,
  minCharacters: 0,
  async: false
}

export default AutocompleteBase
