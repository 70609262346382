import { util } from 'quimera'

export const cargarFiltro = (valores, propiedades) => {
  console.log('loadFiltro', valores)

  const filtro = []
  propiedades.forEach(opcion => {
    if (opcion.nombreCampo in valores) {
      const valor = valores[opcion.nombreCampo]
      const criterio = Object.assign({}, opcion)
      filtro.push({ ...criterio, ...valor })
    }
  })
  return filtro
}

export const volcarFiltro = (filtro) => {
  console.log('dumpFiltro', filtro)

  const valores = {}
  filtro.forEach(clausula => {
    const tipoCampo = clausula.tipoCampo
    const nombreCampo = clausula.nombreCampo
    if (['apiselect', 'string', 'boolean'].includes(tipoCampo)) {
      valores[nombreCampo] = { value: clausula.value }
    }
    if (['checkboxmultiple'].includes(tipoCampo)) {
      valores[nombreCampo] = { opciones: clausula.opciones, value: clausula.value }
    }
    if (['date'].includes(tipoCampo)) {
      valores[nombreCampo] = { value: { ...clausula.value } }
    }
    if (['number'].includes(tipoCampo)) {
      valores[nombreCampo] = { signo: clausula.signo, value: clausula.value }
    }
  })
  console.log('dumped values', valores)
  return valores
}

export const filtrosPredAFiltrosAdaptados = (propiedades, filtrosPredefinidos) => {
  const filtrosPreAdaptados = []

  filtrosPredefinidos.forEach(filtroP => {
    const nuevoFiltro = {}
    nuevoFiltro.tipo = 'filtroDeFiltros'
    nuevoFiltro.nombre = filtroP.descripcion
    nuevoFiltro.labelNombre = filtroP.descripcion
    const filtroJSON = JSON.parse(filtroP.filtro)
    const propiedadesF = []
    propiedades.forEach(p => {
      if (filtroJSON[p.nombreCampo] || (p.otrosIdentificativos && tieneElObjetoAlgunaDeEstasClaves(filtroJSON, p.otrosIdentificativos))) {
        const claveObj = queClaveDeEsteArrayTieneElObjeto(filtroJSON, p.otrosIdentificativos) || (filtroJSON[p.nombreCampo] && p.nombreCampo)

        let valueFiltroP = filtroJSON[claveObj]
        // MAL LOS SUBOBJETOS DE DENTRO SIGUEN TENIENDO LA MISMA REFERENCIIA!! https://platzi.com/blog/como-copiar-objetos-en-javascript-sin-morir-en-el-intento/
        // const aCambiar = Object.assign({}, p) // copiamos el objeto
        const aCambiar = JSON.parse(JSON.stringify(p)) // si da problemas usar la clonacion profunda de jquery
        // const aCambiar = $.extend({}, p) // deep cloning jquery
        p.porDefecto = false
        switch (aCambiar.tipoCampo) {
          case 'string':
            aCambiar.value = rangosEIntervalosString[valueFiltroP] || valueFiltroP
            propiedadesF.push(aCambiar)
            break
          case 'apiselect':
            valueFiltroP = valueFiltroP === 'me' ? util.getUser().user : valueFiltroP // si es idusuario
            aCambiar.value.key = valueFiltroP
            propiedadesF.push(aCambiar)
            break
          case 'boolean':
            aCambiar.value = Boolean(valueFiltroP)
            propiedadesF.push(aCambiar)
            break
          case 'checkboxmultiple':
          {
            const valor = rangosEIntervalosCheckboxmultiple[valueFiltroP] || valueFiltroP
            const tieneValorElValue = aCambiar.opciones.find(op => op.key === valor)
            aCambiar.value = tieneValorElValue ? tieneValorElValue.valor : valor
            // aCambiar.value = valueFiltroP
            // aCambiar.opciones.forEach(op => { op.value = false }) // reseteamos
            aCambiar.opciones.forEach(op => { if (op.key === valor) { op.value = true/* ;  alert(filtroP.descripcion + p.nombreCampo + op.key + ' == ' + valueFiltroP) */ } })
            propiedadesF.push(aCambiar)
            break
          }
          case 'date':
          {
            let fechaValue
            if (rangosEIntervalosFecha[valueFiltroP] && deRangosARangosMostrar[valueFiltroP]) { // si es un rango persistente
              fechaValue = { nombre: deRangosARangosMostrar[valueFiltroP], persistencia: valueFiltroP }
            } else {
              fechaValue = { desde: null, hasta: null, fecha: valueFiltroP }
            }
            aCambiar.value = JSON.parse(JSON.stringify(fechaValue))
            propiedadesF.push(aCambiar)
            break
          }
        }
      }
    })
    nuevoFiltro.propiedades = propiedadesF
    nuevoFiltro.propiedades.length > 0 && filtrosPreAdaptados.push(nuevoFiltro) // si ha encontraado alguno lo añadimos
  })

  return filtrosPreAdaptados
}

const tieneElObjetoAlgunaDeEstasClaves = (obj, arrayClaves) => {
  for (let i = 0; i < arrayClaves.length; i++) {
    if (obj[arrayClaves[i]]) {
      return true
    }
  }
  return false
}

const queClaveDeEsteArrayTieneElObjeto = (obj, arrayClaves) => {
  if (!arrayClaves) {
    return
  }
  let clave
  for (let i = 0; i < arrayClaves.length; i++) {
    if (obj[arrayClaves[i]]) {
      if (arrayClaves[i].substr(0, 2) === 'i_') {
        clave = arrayClaves[i]
      } else {
        if (!clave) {
          clave = arrayClaves[i]
        }
      }
    }
  }
  return clave
}

const rangosEIntervalosFecha = util.rangosEIntervalosFecha()
const deRangosARangosMostrar = util.deRangosARangosMostrar()

const rangosEIntervalosString = {
  thisyear: (new Date()).getFullYear().toString()
}

const rangosEIntervalosCheckboxmultiple = {
  thismonth: ('0' + ((new Date()).getMonth() + 1)).slice(-2)
}
