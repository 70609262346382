import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { loadSubView, useSubView } from 'core/hooks/useManager'
import { TemplateProvider } from 'core/contexts'
import { env } from 'environment'

/**
*   Lets you create Template components for extension/mix system
*/
function SubView ({ id, ...props }) {
  const [loaded, setLoaded] = useState(false)
  const visibleLoading = env.inDevelopment()

  async function initSubView (id) {
    setLoaded(false)
    await loadSubView(id)
    setLoaded(true)
  }

  useEffect(() => {
    initSubView(id)
  }, [id])

  const [, , [SubView, ...parents], style] = useSubView(id)

  return loaded && SubView ? (
    <TemplateProvider value={{
      references: [],
      parents: parents
    }}>
      <SubView parents={parents} useStyles={style} {...props}/>
    </TemplateProvider>
  ) : visibleLoading && (
    <div>Loading Subview...</div>
  )
}

SubView.propTypes = {
  /** Internal id of the SubView */
  id: PropTypes.string.isRequired
}

SubView.defaultProps = {}
export default SubView
