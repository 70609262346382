import React from 'react'
import { Field } from 'quimera-comps'
import TableColumn from 'quimera-comps/Table/TableColumn'

/**
* SelectionColumn
*/
function SelectionColumn ({ ...props }) {
  return (
    <TableColumn columnType="SelectionColumn" id='selection' header='' width={25} align='center' value={
      line => <Field.CheckBox id={ 'selection' } value={ 'checked' in line && line.checked }/>}
    {...props} />
  )
}

export default SelectionColumn
