import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'quimera-comps'
import { Tooltip } from 'thirdparty'
import { A } from 'hookrouter'

import { makeStyles } from 'quimera-styles'

const useStyles = makeStyles(theme => ({
  hoverText: {
    '&:hover': {
      fontWeight: 1000,
      cursor: 'pointer'
    }
  }
}))

// ForwardRef es necesario para que el tooltip pueda escuchar en los elementos interiores del componente
const TableCell = React.forwardRef(function TableCell (tCprops, ref) {
  const classes = useStyles()
  const { children, columnType, isHeader, href, tooltip, ...props } = tCprops

  const myBox = (<Box
    px={0.5}
    overflow='hidden'
    textOverflow='ellipsis'
    whiteSpace='nowrap'
    className={href && classes.hoverText}
    {...props}
    ref={ref}>
    { href
      ? <A href={href} style={{ textDecoration: 'none', color: 'inherit' }} >
        { children }
      </A>
      : children
    }
  </Box>
  )

  return tooltip
    ? <Tooltip title={tooltip}>
      { myBox }
    </Tooltip>
    : myBox
})

TableCell.propTypes = {
  columnType: PropTypes.string,
  children: PropTypes.any,
  href: PropTypes.string,
  isHeader: PropTypes.bool,
  tooltip: PropTypes.string
}

TableCell.defaultProps = {}

export default TableCell
